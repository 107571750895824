import { useNavigate } from "react-router-dom";
import { dateFormatter, fileDownload } from "../../utils/helperFn";
import { useState } from "react";
import {
  deleteWebsite,
  downloadWebsite,
  exportWebsite,
} from "../../Api/website";
import { useDispatch } from "react-redux";
import { websiteFn } from "../../Redux/websiteSlice";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { CustomDropdown } from "../../Components/Dropdown/customDropdown";
import { NotifyUser } from "../../Components/Notification";
import { IDTooltip } from "../../Components/Tooltip/Idtooltip";

export function Site({ obj, fetchSiteData, handleEdit }) {
  const {
    metadata: { siteName },
    id,
    updatedAt,
  } = obj;

  const [showOption, setShowOption] = useState(false);
  const [progress, setProgress] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (id, siteName) => {
    dispatch(websiteFn({ siteName, siteId: id }));
    navigate(`/site/${id}`);
  };

  const handleMoreInfo = (e) => {
    e.stopPropagation();
    setShowOption(true);
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation();
    const response = await deleteWebsite(id);
    if (!response?.error) {
      fetchSiteData();
      NotifyUser({
        type: "success",
        message: `Website Deleted`,
      });
    }
  };

  const handleDownload = async () => {
    setProgress(true);
    const response = await downloadWebsite(id);
    if (response.error) {
      NotifyUser({ type: "error", message: response.message });
    }
    try {
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${siteName}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      NotifyUser({
        type: "error",
        message: "Failed to download folder. Please try again later.",
      });
    }
    setProgress(false);
  };

  const handleExport = async (websiteId) => {
    setProgress(true);
    const response = await exportWebsite(websiteId);
    if (response?.error) {
      NotifyUser({
        type: "error",
        message: "Failed to export website. Please try again later.",
      });
      setProgress(false);

      return;
    }

    setProgress(false);

    NotifyUser({
      type: "success",
      message: `"${siteName}" website exported`,
    });
  };

  const menu = [
    {
      title: "Edit",
      callBack: (e, id) => handleEdit(e, id),
    },
    {
      title: "Delete",
      callBack: (e, id) => handleDelete(e, id),
    },
    {
      title: "Export",
      callBack: () => handleExport(id),
      loading: progress,
    },
    {
      title: "View Pages",
      callBack: () => handleClick(id, siteName),
    },
    // {
    //   title: "Download",
    //   callBack: () => handleDownload(),
    //   loading: progress,
    // },
  ];
  return (
    <div key={id} className="ind-site-list-container">
      <section className="ind-site-details">
        <section>
          <p>{siteName} </p>
          <IDTooltip title="Website ID" value={id} />
          {/* <span className="site-id">{id}</span> */}
        </section>

        <button onClick={handleMoreInfo}>
          <MoreVertOutlinedIcon />
        </button>
        {showOption ? (
          <CustomDropdown
            className="custom-dropdown-container-website"
            showOption={showOption}
            setShowOption={setShowOption}
            menu={menu}
            id={id}
          />
        ) : (
          ""
        )}
      </section>

      <section className="site-update-details">
        <label>Last Updated</label>
        <p className="site-last-update">
          {dateFormatter({
            date: updatedAt,
            format: "DD MMM YYYY, hh:mm a",
          })}
        </p>
      </section>
    </div>
  );
}
