import { useNavigate, useSearchParams } from "react-router-dom";
import "./page.css";
import { BlockResponse } from "../Blocks/BlockResponse";
import { Popup } from "../../../Components/Popup";
import { Button } from "../../../Components/Button";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Blocks } from "../Blocks";
import { useDispatch, useSelector } from "react-redux";
import { blockState, saveBlockFn } from "../../../Redux/blockSlice";
import { websiteState } from "../../../Redux/websiteSlice";
import { NotifyUser } from "../../../Components/Notification";
import { Forms } from "../Forms";
import { formState } from "../../../Redux/formSlice";
import { useState } from "react";

export default function Page() {
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("type");
  const [showBlockResponse, setShowBlockResponse] = useState(false);

  return (
    <main>
      <NavBar pageType={pageType} setShowBlockResponse={setShowBlockResponse} />
      {pageType === "block" ? <Blocks /> : <Forms />}

      {showBlockResponse ? (
        <Popup
          children={
            <div className="block-response-popup">
              <BlockResponse />
            </div>
          }
          popup={showBlockResponse}
          setPopup={setShowBlockResponse}
          title="Block Response"
          className="analytics-code-form-popup"
        />
      ) : (
        ""
      )}
    </main>
  );
}

function NavBar({ pageType, setShowBlockResponse }) {
  const navigate = useNavigate();
  const { blocks, loading: blockLoading } = useSelector(blockState);
  const { loading: formLoading } = useSelector(formState);
  const { siteId: websiteId, pageId } = useSelector(websiteState);
  const dispatch = useDispatch();

  const handleSave = () => {
    if (pageType === "form") {
      return;
    }
    dispatch(
      saveBlockFn({
        data: blocks,
        websiteId,
        pageId,
      })
    );

    NotifyUser({
      type: "success",
      message: `${pageType === "form" ? "Form" : "Blocks"} Saved`,
    });
  };

  return (
    <nav className="nav-bar">
      <section className="nav-bar-sec-1">
        <Button
          title={<KeyboardBackspaceOutlinedIcon />}
          className="navigate-btn"
          onClick={() => navigate(-1)}
        />
      </section>
      <section className="nav-bar-sec-2">
        <Button
          title="Save"
          loading={pageType === "form" ? formLoading : blockLoading}
          onClick={handleSave}
          className="nav-bar-save-btn"
        />
        {pageType === "block" && blocks && blocks.length ? (
          <Button
            title="View Response"
            onClick={() => setShowBlockResponse(true)}
            className="nav-bar-publish-btn"
          />
        ) : (
          ""
        )}
      </section>
    </nav>
  );
}
