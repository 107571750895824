import "./block.css";
import "../Page/";
import { Button } from "../../../Components/Button";
import { useEffect, useState } from "react";
import { BlockLibrary } from "../BlockLibrary";
import { useDispatch, useSelector } from "react-redux";
import {
  CommentField,
  TextField,
} from "../../../Components/TextField/index.js";
import { RichTextEditor } from "../../../Components/TextField/RichTextEditor";
import { currentListFn } from "../../../Redux/listSlice";
import { websiteState } from "../../../Redux/websiteSlice";
import { useParams } from "react-router-dom";
import { Loader } from "../../../Components/Loader";
import { DeleteOutlined } from "@mui/icons-material";
import {
  blockState,
  deleteBlockFn,
  getBlockFn,
  updateBlockContentFn,
} from "../../../Redux/blockSlice";
import { List } from "./List";
import { Group } from "./Group";
import { Link } from "./Link";

export function Blocks() {
  const { blocks, loading } = useSelector(blockState);
  const dispatch = useDispatch();
  const [contentType, setContentType] = useState("block");
  const [showLibrary, setShowLibrary] = useState(false);
  const { pageName, pageId, siteId: websiteId } = useSelector(websiteState);
  const { id } = useParams();

  const handleLibrary = () => {
    setShowLibrary(!showLibrary);
    // const library = document.querySelector(".block-library");
    // const content = document.querySelector(".block-library-content");

    // library.style.maxWidth = library.style.maxWidth === "0px" ? "30%" : "0px";
    // library.style.padding =
    //   library.style.maxWidth === "0px" ? "0px" : "0.75rem";
    // content.style.display = library.style.maxWidth === "0px" ? "none" : "block";
  };

  const handleDelete = (blockId) => {
    dispatch(deleteBlockFn({ blockId, pageId, websiteId }));
  };

  const handleChange = (e, id) => {
    let { name, value } = e.target;
    const filterBlocks = blocks.filter((obj) => obj.id === id);
    if (filterBlocks && filterBlocks.length) {
      let temp = { ...filterBlocks[0] };

      temp.blockData = { ...temp?.blockData, [name]: value };
      dispatch(updateBlockContentFn(temp));
    }
  };

  const getBlockValue = ({ id, variableName }) => {
    const filterBlocks = blocks.filter((obj) => obj.id === id);
    if (filterBlocks && filterBlocks.length) {
      let temp = filterBlocks[0];
      return temp?.blockData?.[variableName]
        ? temp?.blockData?.[variableName]
        : "";
    }
  };

  const handleClick = () => {
    handleLibrary();
    setContentType("block");
    dispatch(currentListFn(""));
  };

  useEffect(() => {
    dispatch(getBlockFn({ pageId, websiteId }));
    // eslint-disable-next-line
  }, [id]);

  return (
    <main className='block-page'>
      {showLibrary ? (
        <BlockLibrary handleLibrary={handleLibrary} contentType={contentType} />
      ) : (
        ""
      )}
      <section className='site-heading-container'>
        <h1>{pageName}</h1>
        <Button title='Block Library' onClick={handleClick} />
      </section>

      <div className='block-list-container'>
        {blocks && blocks.length ? (
          blocks
            .filter((obj) => !obj.nested)
            .map((blockObj, i) => {
              const { blockName, blockType, id } = blockObj;
              if (blockType === "textArea") {
                return (
                  <div className='block-field' key={id + i}>
                    <section className='formLabel delete-btn-container'>
                      {blockName}
                      <button onClick={() => handleDelete(id)}>
                        <DeleteOutlined />
                      </button>
                    </section>
                    <CommentField
                      name={blockName}
                      onChange={(e) => handleChange(e, id)}
                      value={getBlockValue({ id, variableName: blockName })}
                    />
                  </div>
                );
              } else if (blockType === "text") {
                return (
                  <div className='block-field' key={id + i}>
                    <section className='formLabel delete-btn-container'>
                      {blockName}
                      <button onClick={() => handleDelete(id)}>
                        <DeleteOutlined />
                      </button>
                    </section>
                    <TextField
                      name={blockName}
                      onChange={(e) => handleChange(e, id)}
                      value={getBlockValue({ id, variableName: blockName })}
                    />
                  </div>
                );
              } else if (blockType === "rich_text_editor") {
                return (
                  <div className='block-field' key={id + i}>
                    <section className='formLabel delete-btn-container'>
                      {blockName}{" "}
                      <button onClick={() => handleDelete(id)}>
                        <DeleteOutlined />
                      </button>
                    </section>
                    <RichTextEditor
                      name={blockName}
                      onChange={(e) => handleChange(e, id)}
                      value={getBlockValue({ id, variableName: blockName })}
                    />
                  </div>
                );
              } else if (blockType === "link") {
                return (
                  <Link
                    label={blockName}
                    value={getBlockValue({ id, variableName: blockName })}
                    onChange={(e) => handleChange(e, id)}
                    handleDelete={() => handleDelete(id)}
                    key={id + i}
                  />
                );
              } else if (blockType === "list") {
                return (
                  <>
                    <List
                      key={id + i}
                      setContentType={setContentType}
                      block={blockObj}
                      handleLibrary={handleLibrary}
                    />
                  </>
                );
              } else if (blockType === "group") {
                return (
                  <>
                    <Group
                      key={id + i}
                      setContentType={setContentType}
                      block={blockObj}
                      handleLibrary={handleLibrary}
                      handleDelete={handleDelete}
                    />
                  </>
                );
              } else {
                return null;
              }
            })
        ) : blocks && !blocks.length && !loading ? (
          <section className='message-container'>
            <p>No Blocks</p>
          </section>
        ) : (
          <section className='message-container'>
            <Loader />
          </section>
        )}
      </div>
    </main>
  );
}
