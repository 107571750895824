import { useState, useEffect } from "react";
import { emailSubscribe, getPlanDetails } from "../../../Api/settings";
import { NotifyUser } from "../../../Components/Notification/";
import { Loader } from "../../../Components/Loader";
import { Button, ToggleSwitch } from "../../../Components/Button";
import "./pricing.css";

const contactUrl = `${process.env.REACT_APP_LANDING_PAGE_URL}/contact`;

export function Pricing() {
  const [plans, setPlans] = useState("");

  useEffect(() => {
    const fetchPlan = async () => {
      const response = await getPlanDetails();
      if (response.error) {
        NotifyUser({ type: "error", message: response.message });
        return;
      }
      setPlans(response);
      console.log(response);
    };
    fetchPlan();
  }, []);

  const handleClick = (id) => {
    if (id === "Custom") {
      window.open(contactUrl, "_blank");
    }
  };

  return (
    <div className='plans-details'>
      <h1>Pricing</h1>

      <section className='plan-description'>
        <h1>Plans for businesses of all sizes</h1>
        <p>
          Built to scale with any project. Always know what you'll pay,
          predictable pricing that scales when you decide to.
        </p>
      </section>

      <div className='plans-details-container'>
        {plans?.data && plans?.data.length ? (
          plans.data.map(({ id, name, price, features, description, sign }) => {
            return (
              <div key={id} className='ind-plan-details-container'>
                {id === plans?.currentPlans ? (
                  <p className='current-plan-text'>Current Plan</p>
                ) : (
                  ""
                )}
                <p className='ind-plan-name'>{name}</p>
                {price ? (
                  <section className='ind-price-container'>
                    <p className='ind-plan-price'>
                      {price} <sup>{sign}</sup>
                    </p>
                    <span>per month</span>
                  </section>
                ) : (
                  ""
                )}
                <p className='ind-plan-description'>{description}</p>

                {plans.currentPlans !== id ? (
                  <Button
                    title='Get Started'
                    onClick={() => handleClick(id)}
                    className='price-btn'
                  />
                ) : (
                  ""
                )}
                {features && features.length ? (
                  <>
                    <p className='ind-plan-feature-heading'>Features</p>
                    <section className='plan-features-container'>
                      {features.map((item, i) => {
                        return (
                          <p key={i} className='ind-plan-feature'>
                            {" "}
                            <span>✔</span> {item}{" "}
                          </p>
                        );
                      })}
                    </section>
                  </>
                ) : (
                  ""
                )}
              </div>
            );
          })
        ) : (
          <section className='plans-loading-container'>
            {plans && !plans.length ? <p>No Plans Found</p> : <Loader />}
          </section>
        )}
      </div>
      <br />
      <br />

      <Subscription subscribe={plans?.apiSummarySub} />
      <br />
      <br />
      <div>
        <h1>Email</h1>
        <div className='plans-details-container'>
          <div className='ind-plan-details-container'>
            <p className='ind-plan-name'>{plans?.email?.emailUsed}</p>
            <p className='ind-plan-feature'>Email Used </p>
          </div>
          <div className='ind-plan-details-container'>
            <p className='ind-plan-name'>{plans?.email?.emailAvailable}</p>
            <p className='ind-plan-feature'>Available Email</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function Subscription({ subscribe }) {
  const [subscribeStatus, setSubscribeStatus] = useState(subscribe);

  useEffect(() => {
    setSubscribeStatus(subscribe);
  }, [subscribe]);

  const handleChange = async (e) => {
    const response = await emailSubscribe(e.target.checked);
    setSubscribeStatus(response?.apiSummarySub);
    NotifyUser({
      type: "success",
      message: `Email Subscription ${
        response?.apiSummarySub ? "Enabled" : "Disabled"
      }`,
    });
  };

  return (
    <div>
      <section className='subscription-btn-container'>
        <h1>Email Updates</h1>
        <ToggleSwitch value={subscribeStatus} onChange={handleChange} />
      </section>
      <p className='email-subscription-description'>
        Stay informed with daily email updates containing valuable analytics for
        the API you utilize. Enable now to get a daily dose of insights
        delivered straight to your inbox!
      </p>
    </div>
  );
}
