import "./site.css";
import "../SitePages/sitePages.css";
import { Button } from "../../Components/Button";
import { Loader } from "../../Components/Loader";
import { useEffect, useState } from "react";
import { getAllWebsites, importWebsite } from "../../Api/website";
import { Popup } from "../../Components/Popup";
import { SiteCreate } from "./SiteCreate";
import { Site } from "./Site";
import { CustomDropdown } from "../../Components/Dropdown/customDropdown";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Upload } from "../../Components/Upload";
import { NotifyUser } from "../../Components/Notification";
import { formatFileSize } from "../../utils/helperFn";
import { CloseOutlined } from "@mui/icons-material";

export default function Sites() {
  const [sites, setSites] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPagePopup, setShowPagePopup] = useState(false);
  const [selectedSite, setSelectedSite] = useState("");
  const [popupType, setPopupType] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [importPopup, setImportPopup] = useState(false);

  const fetchSiteData = async () => {
    setLoading(true);
    const response = await getAllWebsites();
    setSites(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchSiteData();
  }, []);

  const handleEdit = (e, id) => {
    e.stopPropagation();
    const filterSite = sites.filter((obj) => obj.id === id);
    if (!filterSite[0]) {
      return;
    }
    setPopupType("update");
    setSelectedSite(filterSite[0]);
    setShowPagePopup(true);
  };

  const menu = [
    {
      title: "Create",
      callBack: (e) => {
        setShowPagePopup(true);
        setPopupType("create");
      },
    },
    {
      title: "Import",
      callBack: () => setImportPopup(true),
    },
  ];

  return (
    <main className="site-page">
      {showPagePopup ? (
        <Popup
          children={
            <SiteCreate
              setShowPagePopup={setShowPagePopup}
              fetchSiteData={fetchSiteData}
              selectedSite={selectedSite}
              popupType={popupType}
            />
          }
          popup={showPagePopup}
          setPopup={setShowPagePopup}
          title="New Website"
        />
      ) : (
        ""
      )}

      {importPopup ? (
        <Popup
          children={
            <ImportWebsite
              fetchSiteData={fetchSiteData}
              setImportPopup={setImportPopup}
            />
          }
          popup={importPopup}
          setPopup={setImportPopup}
          title="Import Website"
          className="import-website-popup"
        />
      ) : (
        ""
      )}
      <section className="site-heading-container">
        <h1> Websites</h1>
        <Button title="Add website" onClick={() => setShowMenu(true)} />
        {showMenu ? (
          <CustomDropdown
            className="custom-dropdown-container-add-website"
            showOption={showMenu}
            setShowOption={setShowMenu}
            menu={menu}
            id={""}
          />
        ) : (
          ""
        )}
      </section>

      <section className="site-list-container">
        {sites && sites.length ? (
          sites.map((obj, i) => {
            return (
              <Site
                obj={obj}
                key={i}
                handleEdit={handleEdit}
                fetchSiteData={fetchSiteData}
              />
            );
          })
        ) : sites && !loading ? (
          <section className="message-container">
            <p>No Recent Websites</p>
          </section>
        ) : (
          <section className="message-container">
            <Loader />
          </section>
        )}
      </section>
    </main>
  );
}

function ImportWebsite({ fetchSiteData, setImportPopup }) {
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [fileDetail, setFileDetail] = useState("");

  const handleImport = async () => {
    setLoading(true);
    const response = await importWebsite(uploadedFile);
    if (response.error) {
      setLoading(false);

      NotifyUser({
        type: "error",
        message: response.message,
      });
      return;
    }
    fetchSiteData();
    NotifyUser({
      type: "success",
      message: `Website Imported`,
    });
    setLoading(false);
    setImportPopup(false);
  };

  const Children = (
    <section className="upload-file-design">
      <CloudUploadOutlinedIcon id="cloud-upload-icon" />
      <p>Upload</p>
      <p>Supported format .zip</p>
    </section>
  );

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setUploadedFile(formData);
    setFileDetail({ name: file.name, size: file.size });
  };

  const deleteFile = () => {
    setFileDetail("");
    setUploadedFile("");
  };

  return (
    <div className="import-website-container">
      {uploadedFile ? (
        <div className="import-website-details">
          <div className="import-website-details-container">
            <p>{fileDetail?.name}</p>
            <p>{formatFileSize(fileDetail?.size)}</p>
            <button onClick={deleteFile}>
              <CloseOutlined />
            </button>
          </div>
          <Button
            title="Save Website"
            className="import-website-btn"
            onClick={handleImport}
            loading={loading}
          />
        </div>
      ) : (
        <Upload
          Children={Children}
          onChange={handleUpload}
          accept=".zip"
          multiple={false}
        />
      )}
    </div>
  );
}
