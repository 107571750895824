import axios from "axios";
import { getItem } from "../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const createBlock = async ({ data, pageId, websiteId, type }) => {
  try {
    const response = await axios({
      url: `${base_url}/page/${pageId}/block?websiteId=${websiteId}&type=${type}`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const updateBlock = async ({ data, pageId, websiteId, blockId, type }) => {
  try {
    const response = await axios({
      url: `${base_url}/page/${pageId}/block/${blockId}?websiteId=${websiteId}&type=${type}`,
      method: "PUT",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const updateAllBlocks = async ({ data, pageId, websiteId, type }) => {
  try {
    const response = await axios({
      url: `${base_url}/page/${pageId}/blocks?websiteId=${websiteId}&type=${type}`,
      method: "PUT",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteBlock = async ({ pageId, blockId, websiteId, type, ...obj }) => {
  try {
    const response = await axios({
      url: `${base_url}/page/${pageId}/block/${blockId}?websiteId=${websiteId}&type=${type}`,
      method: "DELETE",
      data: obj,
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getBlockResponse = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/page/parse`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export {
  createBlock,
  updateAllBlocks,
  deleteBlock,
  updateBlock,
  getBlockResponse,
};
