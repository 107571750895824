import axios from "axios";
import { getItem } from "../../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const createWaitlist = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/waitlist`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const updateWaitlist = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/waitlist/${data.id}`,
      method: "PUT",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAllWaitlist = async () => {
  try {
    const response = await axios({
      url: `${base_url}/integration/waitlist`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getWaitlistById = async (id) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/waitlist/${id}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getWaitlistCode = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/waitlist/${data.id}?type=code&domain=${data.domain}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteWaitlist = async (id) => {
  try {
    await axios({
      url: `${base_url}/integration/waitlist/${id}`,
      method: "DELETE",
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export {
  createWaitlist,
  getAllWaitlist,
  getWaitlistById,
  deleteWaitlist,
  updateWaitlist,
  getWaitlistCode,
};
