import { useState, useEffect } from "react";
import { Loader } from "../../../../Components/Loader";
import { Copy } from "../../../../Components/Copy";
import { CodeDisplay } from "../../../../Components/Code";
import { getMixPanelCode } from "../../../../Api/Integration/mixpanel";

export function CodeExample({ obj }) {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCode = async () => {
      setLoading(true);
      const response = await getMixPanelCode(obj);
      setCode(response);
      console.log(response);
      setLoading(false);
    };
    fetchCode();
  }, [obj]);

  return (
    <main className="code-content">
      {code ? (
        <>
          <Copy
            content={code}
            iconId="code-copy-icon"
            btnClassName="copy-mixpanel-code-btn"
          />
          {/* <pre>{code}</pre> */}
          <CodeDisplay code={code} />
        </>
      ) : !code && !loading ? (
        <section>
          <p>Code Generation Failed</p>
        </section>
      ) : (
        <section>
          <Loader />
        </section>
      )}
    </main>
  );
}
