import { useState, useEffect } from "react";
import { Button } from "../../../../Components/Button";
import { NotifyUser } from "../../../../Components/Notification";
import { useSearchParams } from "react-router-dom";
import { sheetsApi } from "../../../../Api/Integration/googlesheets";
import { TextField } from "../../../../Components/TextField";

export function CreateSheet({
  fetchSheets,
  setShowPopup,
  popupType,
  selectedSpreadsheet: spreadsheetDetail,
}) {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get("account");
  const [sheetDetails, setSheetDetails] = useState({
    sheetId: "",
    sheetName: "",
    id: accountId,
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (popupType === "edit") {
      setSheetDetails({
        sheetId: spreadsheetDetail.id,
        sheetName: spreadsheetDetail.name,
        id: accountId,
      });
    }
    // eslint-disable-next-line
  }, [popupType, spreadsheetDetail, setSheetDetails]);

  const formList = [
    {
      label: "Worksheet Name",
      variableName: "sheetName",
    },
    {
      label: "Spreadsheet Id",
      variableName: "sheetId",
    },
  ];

  const handleChange = (e) => {
    let { name, value } = e.target;
    setMessage("");
    setSheetDetails((obj) => ({ ...obj, [name]: value }));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    for (let i in sheetDetails) {
      if (!sheetDetails[i]) {
        setMessage("All fields required");
        return;
      }
    }
    setMessage("");
    setLoading(true);

    const obj = {
      id: accountId,
      ...sheetDetails,
    };
    const response = await sheetsApi(obj);

    if (response.error) {
      NotifyUser({ type: "error", message: response.message });
      setLoading(false);
      return;
    }
    setLoading(false);
    NotifyUser({
      type: "success",
      message: `"${sheetDetails.sheetName}" api created`,
    });

    await fetchSheets();
    setShowPopup(false);
  };

  return (
    <div className="integration-sheetList-container">
      <form className="googlesheet-form-container">
        {formList.map(({ label, variableName }, i) => {
          return (
            <div key={i} className="googlesheet-ind-form-field">
              <label className="formLabel">{label}</label>
              <TextField
                // className="google-sheet-form-field"
                type="text"
                value={sheetDetails[variableName]}
                name={variableName}
                onChange={handleChange}
              />
            </div>
          );
        })}
      </form>
      <span className="form-err-msg">{message}</span>
      <Button
        title={popupType}
        className="sheet-create-btn"
        loading={loading}
        onClick={handleSave}
      />
    </div>
  );
}
