import { imgObj } from "../../utils/image";
import { Button } from "../../Components/Button";
import { useNavigate, useParams } from "react-router-dom";

export function SuccessPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const message = {
    heading: id === "password" ? "Password  Updated" : "Email Verified",
    description: id === "password" ? "Your Password have been saved" : "",
  };

  return (
    <div className="forgotpassword-page">
      <div className="auth-success-container">
        <div class="auth-success-img-container">
          <img src={imgObj.logo} class="auth-login-logo" alt="logo" />
          <img
            src={imgObj.forgotPassword3}
            class="forgot-password-illustration-img"
            alt="illustration"
          />
        </div>

        <div className="auth-success-page-content-container">
          <p className="auth-success-heading">{message.heading}</p>
          <br />
          <img
            src={imgObj.successImg}
            alt="success"
            className="auth-success-img"
          />

          <div className="forgot-password-info-container">
            <p>{message.description}</p>
          </div>
          <Button
            title="Login"
            className="auth-success-login-btn"
            onClick={() => navigate("/")}
          />
          <br />
        </div>
      </div>
      <img src={imgObj.wave} class="login-wave-img" alt="wave" />
    </div>
  );
}
