import axios from "axios";
import { getItem } from "../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const apiKeyFn = async (method) => {
  try {
    const response = await axios({
      url: `${base_url}/user/key`,
      method,
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getApiList = async () => {
  try {
    const response = await axios({
      url: `${base_url}/user/api`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getPlanDetails = async () => {
  try {
    const response = await axios({
      url: `${base_url}/user/plans?notifyType=summary`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });

    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const handleDomainApi = async ({ method, query, data }) => {
  try {
    const response = await axios({
      url: `${base_url}/user/domain${query ? query : ""}`,
      method,
      data,
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const emailSubscribe = async (type) => {
  try {
    const response = await axios({
      url: `${base_url}/user/notification?type=${
        type ? "subscribe" : "unsubscribe"
      }&notifyType=summary`,
      method: "POST",
      headers: { Authorization: getItem("token") },
    });

    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export {
  apiKeyFn,
  getApiList,
  getPlanDetails,
  handleDomainApi,
  emailSubscribe,
};
