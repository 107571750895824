import { Button } from "../../../../Components/Button";
import { TextField } from "../../../../Components/TextField";
import { NotifyUser } from "../../../../Components/Notification/index.js";
import { useState, useEffect } from "react";
import {
  createAuthIntegration,
  updateAuthIntegration,
} from "../../../../Api/Integration/auth";
import { DeleteOutlined } from "@mui/icons-material";

export function SiteCreate({
  setShowPopup,
  fetchSiteData,
  selectedSite,
  popupType,
}) {
  const [input, setInput] = useState({
    name: "",
    fields: ["email"],
    brand: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const form = [
    {
      label: "Website Name",
      type: "text",
      variableName: "name",
    },
    {
      label: "Brand",
      type: "text",
      variableName: "brand",
    },
    {
      label: "Fields",
      type: "group",
      variableName: "fields",
    },
  ];

  useEffect(() => {
    if (
      selectedSite &&
      Object.keys(selectedSite).length &&
      popupType === "update"
    ) {
      setInput(selectedSite);
    }
  }, [selectedSite, popupType]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let temp = { ...input };
    temp[name] = value;
    setInput({ ...temp });
    setError("");
  };

  const handleClick = async () => {
    if (!Object.keys(input).length) {
      setError("All Fields Required");

      return;
    }

    for (let i in input) {
      if (input[i] === "") {
        setError("All Fields Required");
        return;
      }
    }

    let tempInput = { ...input };

    tempInput.fields = tempInput.fields
      .filter((item) => item)
      .map((item) => item.trim());
    console.log(tempInput);

    // return;
    setError("");

    setLoading(true);

    let response;

    if (popupType === "create") {
      response = await createAuthIntegration(tempInput);
    } else if (popupType === "update") {
      response = await updateAuthIntegration(tempInput);
    }

    setLoading(false);
    if (!response.error) {
      NotifyUser({
        type: "success",
        message: `Website ${popupType}d successfully`,
      });
      setShowPopup(false);
    } else {
      setError(response.message);
    }
    fetchSiteData();
  };

  const handleFieldsChange = (e) => {
    let { name, value } = e.target;
    setError("");
    const temp = { ...input };
    if (Array.isArray(temp.fields)) {
      temp.fields[name] = value;
    } else {
      temp.fields = [value];
    }
    setInput(temp);
  };

  const handleAddFields = () => {
    let temp = { ...input };
    if (Array.isArray(temp.fields)) {
      temp.fields.push("");
    } else {
      temp.fields = [temp.fields, ""];
    }
    setInput(temp);
  };

  const handleDeleteFields = (i) => {
    let temp = { ...input };
    const filterFields = temp.fields.filter((data, index) => index !== i);
    temp.fields = filterFields;
    setInput(temp);
    setError("");
  };

  return (
    <main>
      <form className='page-create-form-auth-integration'>
        {form.map(({ label, type, variableName }) => {
          if (type === "text") {
            return (
              <div className='ind-page-create-field' key={variableName}>
                <label className='formLabel'>{label}</label>
                <TextField
                  type={type}
                  onChange={handleChange}
                  name={variableName}
                  value={input[variableName]}
                />
              </div>
            );
          } else if (type === "group") {
            const domainArr = Array.isArray(input[variableName])
              ? input[variableName]
              : [""];
            return (
              <div className='ind-captcha-create-field' key={variableName}>
                <label className='formLabel'>{label}</label>
                <div className='recaptcha-domain-field-container'>
                  {domainArr.map((item, i) => {
                    return (
                      <div className='recaptcha-domain-ind-field-container'>
                        <TextField
                          type={type}
                          key={i}
                          onChange={handleFieldsChange}
                          name={i}
                          value={item}
                          readOnly={i === 0 ? true : false}
                        />
                        {i !== 0 ? (
                          <button
                            type='button'
                            onClick={() => handleDeleteFields(i)}
                          >
                            <DeleteOutlined />
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
                <Button
                  type='button'
                  title='Add Field'
                  className='add-field-recaptcha-btn'
                  onClick={handleAddFields}
                />
              </div>
            );
          }

          return null;
        })}

        <p className='auth-error-message'>{error ? error : ""}</p>

        <Button
          title={popupType === "create" ? "Create" : "Update"}
          type='button'
          className='site-create-btn'
          onClick={handleClick}
          loading={loading}
        />
      </form>
    </main>
  );
}
