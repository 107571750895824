import { useState, useEffect } from "react";
import { Button } from "../../../../Components/Button";
import {
  createCloudinaryAccount,
  updateCloudinaryAccount,
} from "../../../../Api/Integration/cloudinary";
import { NotifyUser } from "../../../../Components/Notification";
import { TextField } from "../../../../Components/TextField/index.js";

export function AccountCreate({
  fetchCredentials,
  selectedAccount,
  popupType,
  setShowPopup,
}) {
  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const form = [
    {
      label: "Cloud Name",
      type: "text",
      variableName: "cloud_name",
    },
    {
      label: "Api Key",
      type: "text",
      variableName: "api_key",
    },
    {
      label: "Api Secret",
      type: "text",
      variableName: "api_secret",
    },
  ];

  useEffect(() => {
    if (
      selectedAccount &&
      Object.keys(selectedAccount).length &&
      popupType === "update"
    ) {
      setInput(selectedAccount);
    }
  }, [selectedAccount, popupType]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let temp = { ...input };
    temp[name] = value;
    setInput({ ...temp });
    setError("");
  };

  const handleClick = async () => {
    if (input && !Object.keys(input).length) {
      setError("All Fields Required");

      return;
    }
    for (let i in input) {
      if (!input[i]) {
        setError("All Fields Required");
        return;
      }
    }
    console.log(input);
    // return;
    setError("");

    setLoading(true);

    let response;

    if (popupType === "create") {
      response = await createCloudinaryAccount(input);
    } else if (popupType === "update") {
      response = await updateCloudinaryAccount(input);
    }

    setLoading(false);
    if (!response.error) {
      NotifyUser({
        type: "success",
        message: `Account ${popupType}d successfully`,
      });
    } else {
      setError(response.message);
    }
    fetchCredentials();

    setShowPopup(false);
  };

  return (
    <main>
      <form className="page-create-form">
        {form.map(({ label, type, variableName }) => {
          if (type === "text") {
            return (
              <div className="ind-page-create-field" key={variableName}>
                <label className="formLabel">{label}</label>
                <TextField
                  type={type}
                  onChange={handleChange}
                  name={variableName}
                  value={input[variableName]}
                />
              </div>
            );
          }
          return null;
        })}

        {error ? <p className="auth-error-message">{error}</p> : ""}

        <Button
          title={popupType === "create" ? "Create" : "Update"}
          type="button"
          className="site-create-btn"
          onClick={handleClick}
          loading={loading}
        />
      </form>
    </main>
  );
}
