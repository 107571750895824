import "./dropdown.css";
import { useRef } from "react";
import { LoadingOutlined } from "@ant-design/icons";

export function CustomDropdown({
  showOption,
  setShowOption,
  menu,
  id,
  className,
  pageName,
}) {
  const ShowMenu = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      ShowMenu.current &&
      showOption &&
      !ShowMenu.current.contains(e.target)
    ) {
      setShowOption(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  const handleClick = ({ e, id, pageName, callBack, loading }) => {
    callBack(e, id, pageName);
    if (loading === undefined) {
      setShowOption(false);
    }
  };

  return (
    <div className={`custom-dropdown-container ${className}`} ref={ShowMenu}>
      {menu.map(({ title, callBack, loading }, i) => {
        return (
          <button
            onClick={(e) => handleClick({ e, id, pageName, callBack, loading })}
            key={i}
          >
            {title}
            {loading ? <LoadingOutlined spin delay={500} /> : ""}
          </button>
        );
      })}
    </div>
  );
}
