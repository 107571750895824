import { useState, useEffect, useRef } from "react";
import { Button } from "../../../../Components/Button";
import { Loader } from "../../../../Components/Loader";
import { NotifyUser } from "../../../../Components/Notification";
import { useNavigate, useSearchParams } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { io } from "socket.io-client";
import { getItem } from "../../../../utils/helperFn";
import { getAllGoogleAccounts } from "../../../../Api/Integration/googlesheets";
import { Account } from "./Account";
import { SheetList } from "./SheetList";

const googleLogo = "/googleIcon.png";

export function GoogleSheets() {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get("account");
  const token = getItem("token");

  // Socket
  const [socketId, setSocketId] = useState("");
  const [myWindow, setmyWindow] = useState("");
  const [socketMessage, setSocketMessage] = useState("");
  const hostUrl = process.env.REACT_APP_HOST_URL;
  const redirectUrl = `${hostUrl}/integration/googleSheets/oauth?token=${token}&socketId=${socketId}`;

  useEffect(() => {
    const socket = io(hostUrl);

    socket.on("connect", () => {
      console.log("socket-connected", socket.id);
      setSocketId(socket.id);
    });

    socket.on("message", (serverMessage) => {
      setSocketMessage(serverMessage);
    });

    return () => socket.disconnect();
  }, [hostUrl]);

  var val = useRef("");

  function open() {
    if (socketMessage.message === "progress") {
      val.current = window.open(`${redirectUrl}`, "", "width=500,height=500");

      setmyWindow(val.current);
    }
    if (socketMessage.message === "Integration Successful") {
      myWindow.close();

      NotifyUser({
        message: "Authentication Successful",
        type: "success",
      });
      fetchAccounts();
      setSocketMessage("");
    }
    if (socketMessage.message === "Integration Unsuccessful") {
      myWindow.close();
      NotifyUser({
        message: "Authentication Failed",
        type: "error",
      });
    }
  }
  // eslint-disable-next-line
  useEffect(open, [socketMessage, redirectUrl, socketId]);

  useEffect(() => {
    const checkPopup = setInterval(() => {
      if (val.current) {
        if (val.current.closed) {
          clearInterval(checkPopup);
          if (socketMessage && socketMessage.message === "progress") {
            setSocketMessage("");
            NotifyUser({
              message: "Error Occurred, Try Again",
              type: "error",
            });
          }
        }
      }
    }, 500);
    // eslint-disable-next-line
  }, [val]);

  //   useEffect(() => {
  //     if (socketMessage.message === "progress" && popupStatus) {
  //       NotifyUser({
  //         message: "Error Occurred, Try Again",
  //         type: "error",
  //       });
  //       setSocketMessage("");
  //     }
  //   }, [socketMessage,popupStatus]);

  const fetchAccounts = async () => {
    setLoading(true);
    const response = await getAllGoogleAccounts();
    setAccounts(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  return !accountId ? (
    <main className="ind-integration-page">
      <section className="site-heading-container">
        <h1>
          {" "}
          <Button
            title={<KeyboardBackspaceOutlinedIcon />}
            className="navigate-btn"
            onClick={() => navigate(-1)}
          />
          GoogleSheets
        </h1>
        {accounts && !accounts.length ? (
          <Button
            title="Sign In with Google"
            className="googleSheet-signin-btn"
            icon={
              <img
                src={googleLogo}
                className="googleSheet-signin-icon"
                alt="google"
              />
            }
            onClick={() => {
              setSocketMessage({ message: "progress" });
            }}
          />
        ) : (
          ""
        )}
      </section>
      <section className="site-list-container">
        {accounts && Array.isArray(accounts) && accounts?.length ? (
          accounts?.map((obj, i) => {
            return <Account obj={obj} key={i} fetchAccounts={fetchAccounts} />;
          })
        ) : accounts && !loading ? (
          <section className="message-container">
            <p>No Accounts Found</p>
          </section>
        ) : (
          <section className="message-container">
            <Loader />
          </section>
        )}
      </section>
    </main>
  ) : (
    <SheetList />
  );
}
