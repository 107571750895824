import "../Blocks/block.css";
import "../Page";
import { Button } from "../../../Components/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { websiteState } from "../../../Redux/websiteSlice";
import { Loader } from "../../../Components/Loader";
import { FormLibrary } from "../FormLibrary/formLibrary";
import { formState } from "../../../Redux/formSlice";
import { Popup } from "../../../Components/Popup";
import { FormTable } from "./formTable";
import { NotifyUser } from "../../../Components/Notification";
import { CodeDisplay } from "../../../Components/Code";
import { Copy } from "../../../Components/Copy";
import { getFormFn } from "../../../Redux/formSlice";
import { getFormCode, getFormTypes } from "../../../Api/form";
import { IndForm } from "./IndForm";

export function Forms() {
  const { forms, loading } = useSelector(formState);
  const { pageName, pageId, siteId: websiteId } = useSelector(websiteState);
  const [formObj, setFormObj] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const dispatch = useDispatch();
  const [showLibrary, setShowLibrary] = useState(false);
  const [formTypes, setFormTypes] = useState([]);

  const handleLibrary = () => {
    setShowLibrary(!showLibrary);
  };
  useEffect(() => {
    dispatch(getFormFn({ pageId, websiteId }));
    // eslint-disable-next-line
  }, [showResponse]);

  useEffect(() => {
    const fetchTypes = async () => {
      const response = await getFormTypes();
      if (response.error) {
        NotifyUser({ type: "error", message: response.message });
      }

      const modifiedResponse = response.map((item) => ({
        label: item,
        value: item.toLowerCase(),
      }));

      setFormTypes(modifiedResponse);
    };
    fetchTypes();
  }, []);

  return (
    <main className="block-page">
      {showLibrary ? (
        <FormLibrary handleLibrary={handleLibrary} formTypes={formTypes} />
      ) : (
        ""
      )}
      <section className="site-heading-container">
        <h1>{pageName}</h1>
        <Button title="Create Form" onClick={handleLibrary} />
      </section>

      {showResponse ? (
        <Popup
          children={<FormTable formData={formObj} />}
          popup={showResponse}
          setPopup={setShowResponse}
          className="form-response-popup"
          title={formObj.formName}
        />
      ) : (
        ""
      )}

      {showCode ? (
        <Popup
          children={
            <CodeGeneration setShowCode={setShowCode} selectedPage={formObj} />
          }
          popup={showCode}
          setPopup={setShowCode}
          title="Sample Code"
          className="integration-code-popup"
        />
      ) : (
        ""
      )}

      <div className="block-list-container">
        {forms && forms.length ? (
          forms.map((obj) => {
            return (
              <IndForm
                obj={obj}
                setFormObj={setFormObj}
                setShowResponse={setShowResponse}
                setShowCode={setShowCode}
              />
            );
          })
        ) : forms && !forms.length && !loading ? (
          <section className="message-container">
            <p>No Form</p>
          </section>
        ) : (
          <section className="message-container">
            <Loader />
          </section>
        )}
      </div>
    </main>
  );
}

function CodeGeneration({ setShowCode, selectedPage }) {
  const { formId } = selectedPage;
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  const getContent = () => {
    if (Object.keys(code).length) {
      const variables = code.variables.join(";");
      const result = code.mainCode + variables;
      return result;
    }
    return "";
  };

  useEffect(() => {
    const fetchCode = async () => {
      setLoading(true);
      const response = await getFormCode({ formId });
      try {
        if (response) {
          const splitResponse = response.split("###!!###");
          const mainCode = splitResponse[0];
          const variables = splitResponse[1]?.split(";");
          setCode({ mainCode, variables });
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setLoading(false);
    };
    fetchCode();
  }, [formId]);
  return (
    <main style={{ padding: "1rem" }}>
      {code ? (
        <>
          <Copy
            content={getContent()}
            iconId="code-copy-icon"
            btnClassName="copy-code-btn"
          />

          <CodeDisplay code={code.mainCode} />
          <div className="code-variables-container">
            {code.variables && code.variables.length
              ? code.variables.map((item, i) => {
                  return <CodeDisplay code={item} />;
                })
              : ""}
          </div>
        </>
      ) : !code && !loading ? (
        <section className="message-container">
          <p>Code Generation Failed</p>
        </section>
      ) : (
        <section className="message-container">
          <Loader />
        </section>
      )}
    </main>
  );
}
