import { useEffect, useState } from "react";
import { CommentField, TextField } from "../../Components/TextField";
import { Button } from "../../Components/Button";
import { createWebsite, updateWebsite } from "../../Api/website.js";
import { NotifyUser } from "../../Components/Notification/index.js";

export function SiteCreate({
  setShowPagePopup,
  fetchSiteData,
  selectedSite,
  popupType,
}) {
  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const form = [
    {
      label: "Site Name",
      type: "text",
      variableName: "siteName",
    },
    {
      label: "URL",
      type: "text",
      variableName: "siteUrl",
    },
    {
      label: "Description",
      type: "comment",
      variableName: "description",
    },
  ];

  useEffect(() => {
    if (
      selectedSite &&
      Object.keys(selectedSite).length &&
      popupType === "update"
    ) {
      setInput(selectedSite.metadata);
    }
  }, [selectedSite, popupType]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let temp = { ...input };
    temp[name] = value;
    setInput({ ...temp });
    setError("");
  };

  const handleClick = async () => {
    if (!input.siteName) {
      return;
    }
    setError("");

    setLoading(true);

    let response;

    if (popupType === "create") {
      response = await createWebsite({ metadata: { ...input } });
    } else if (popupType === "update") {
      response = await updateWebsite(
        { metadata: { ...input } },
        selectedSite.id
      );
    }

    setLoading(false);
    if (!response.error) {
      NotifyUser({
        type: "success",
        message: `Website ${popupType}d successfully`,
      });
    } else {
      NotifyUser({ type: "error", message: response.message });
      return;
    }
    fetchSiteData();

    setShowPagePopup(false);
  };

  return (
    <main>
      <form className="page-create-form">
        {form.map(({ label, type, variableName }) => {
          if (type === "text") {
            return (
              <div className="ind-page-create-field" key={variableName}>
                <label>{label}</label>
                <TextField
                  type={type}
                  onChange={handleChange}
                  name={variableName}
                  value={input[variableName]}
                />
              </div>
            );
          } else if (type === "comment") {
            return (
              <div className="ind-page-create-field" key={variableName}>
                <label>{label}</label>

                <CommentField
                  onChange={handleChange}
                  value={input[variableName]}
                  name={variableName}
                />
              </div>
            );
          }
          return null;
        })}

        {error ? <p className="auth-error-message">{error}</p> : ""}

        <Button
          title={popupType === "create" ? "Create" : "Update"}
          type="button"
          className="site-create-btn"
          onClick={handleClick}
          loading={loading}
        />
      </form>
    </main>
  );
}
