import { dateFormatter } from "../../utils/helperFn";

const formatQueryDate = (date) => dateFormatter({ date, format: "YYYY-MM-DD" });

const isoConvertion = (iso) => {
  let modifiedDate = dateFormatter({ date: iso, format: "DD MMM YY" });
  //   let modifiedTime = dateFormatter({ date: iso, format: "HH:mm" });
  return {
    modifiedDate,
    // modifiedTime,
  };
};

const modifyArr = (arr) => {
  try {
    let tempArr = JSON.parse(JSON.stringify(arr));
    return tempArr.map((obj) => {
      let isoObj = isoConvertion(obj.date);
      obj.originalDate = obj.date;
      obj.date = isoObj.modifiedDate;
      //   obj.time = isoObj.modifiedTime;
      return obj;
    });
  } catch (error) {
    console.log(error);
    return;
  }
};

export { formatQueryDate, modifyArr };
