import { useState } from "react";
import { HiddenField, TextField } from "../../Components/TextField";
import { Button } from "../../Components/Button";
import { authApi } from "../../Api/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginFn } from "../../Redux/profileSlice";
import { useGoogleLogin } from "@react-oauth/google";
import { CheckBox } from "../../Components/Checkbox";
import "./auth.css";

const googleIcon = "/googleIcon.png";
const wave = "/wave.png";
const termsConditionUrl = `${process.env.REACT_APP_LANDING_PAGE_URL}/terms-and-conditions`;
const logo = "/logo.png";

export default function Register() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsConditions: false,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const faq = [
    {
      question: "Why should we prefer Nexa Flow?",
      answer:
        "Lorem ipsum dolor sit amet elit. Sapiente sit aut eos consectetur adipisicing.",
    },
    {
      question: "Why should we prefer Nexa Flow?",
      answer:
        "Lorem ipsum dolor sit amet elit. Sapiente sit aut eos consectetur adipisicing.",
    },
    {
      question: "Why should we prefer Nexa Flow?",
      answer:
        "Lorem ipsum dolor sit amet elit. Sapiente sit aut eos consectetur adipisicing.",
    },
    {
      question: "Why should we prefer Nexa Flow?",
      answer:
        "Lorem ipsum dolor sit amet elit. Sapiente sit aut eos consectetur adipisicing.",
    },
  ];

  const formDetails = [
    {
      variablename: "name",
      label: "Username",
    },
    {
      variablename: "email",
      label: "email",
      validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message: "Invalid Email",
    },
    {
      variablename: "password",
      label: "Password",
      validation: (value) =>
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          value
        ),
      message:
        "Password should have at least 8 characters, a letter, a number, and a special character",
    },
    {
      variablename: "confirmPassword",
      label: "Confirm Password",
      validation: () => formData.password === formData.confirmPassword,
      message: "Password & Confirm Password should be same",
    },
    {
      variablename: "termsConditions",
      label: "Terms & Conditions",
    },
  ];

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setMessage("");
  };

  const validate = () => {
    for (let i in formDetails) {
      let { label, variablename, message, validation } = formDetails[i];
      let value = formData[variablename];
      if (!value) {
        setMessage({ type: "error", result: `${label} is required` });
        return;
      }

      if (validation && !validation(value)) {
        setMessage({ type: "error", result: message });
        return;
      }
    }
    return "validated";
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }

    setLoading(true);
    const { response, error, message } = await authApi({
      data: formData,
      type: "register",
    });
    setLoading(false);
    if (!error) {
      // dispatch(loginFn({ ...response, isLoggedIn: true }));
      // navigate("/site/view");
      setMessage({
        type: "success",
        result: "Verification link sent to the email",
      });
    } else {
      setMessage({ type: "error", result: message });
    }
  };

  const googleLogin = async (token) => {
    if (!token?.access_token) {
      return;
    }

    const { response, error, message } = await authApi({
      data: { idToken: token?.access_token },
      type: "google-register",
    });
    if (!error) {
      dispatch(loginFn({ ...response, isLoggedIn: true }));
      navigate("/site/view");
    } else {
      setMessage({ type: "error", result: message });
    }
  };

  return (
    <div className="register-page">
      <p className="auth-mobile-heading">Register</p>
      <div className="register-container">
        <img src={logo} className="auth-register-logo" alt="logo" />

        <div className="register-description-container">
          {/* <p className="register-heading">Info</p> */}
          <p className="register-description">
            Welcome to Nexaflow
            <br />
            <br /> Bring Your Customise website to life in rocket speed
          </p>

          {/* <div className="register-faq-container">
            <p className="register-heading">FAQ</p>
            {faq && faq.length
              ? faq.map(({ question, answer }, i) => (
                  <FAQ question={question} answer={answer} key={i} />
                ))
              : ""}
          </div> */}
        </div>

        {/* <div className="register-divider" /> */}

        <div className="register-content-container">
          <div>
            <p className="register-heading">Register</p>
            {/* <p className="register-description">
              Lorem ipsum dolor sit amet elit. Sapiente sit aut eos consectetur
              adipisicing.
            </p> */}
          </div>

          <div className="register-field-container">
            <TextField
              name="name"
              onChange={handleChange}
              value={formData.name}
              className="register-field-name"
              placeholder="Username"
              autoFocus={true}
            />
            <div className="field-divider" />
            <TextField
              name="email"
              onChange={handleChange}
              value={formData.email}
              className="register-field-email"
              placeholder="Email"
            />
            <br />
            <HiddenField
              name="password"
              onChange={handleChange}
              value={formData.password}
              className="register-field-password"
              placeholder="Password"
            />
            <div className="field-divider" />
            <HiddenField
              name="confirmPassword"
              onChange={handleChange}
              value={formData.confirmPassword}
              className="register-field-confirm-password"
              placeholder="Confirm Password"
            />
            <p
              className={
                message?.type === "success"
                  ? "login-success-message"
                  : "login-err-message"
              }
            >
              {message?.result}
            </p>
          </div>
          <div className="register-checkbox-container">
            <section>
              <CheckBox
                checked={formData.termsConditions}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: "termsConditions",
                      value: e.target.checked,
                    },
                  })
                }
                title={""}
              />
              <p className="termsCondition-text">
                I agree to the{" "}
                <a href={termsConditionUrl} rel="noreferrer" target="_blank">
                  Terms & Condition
                </a>
              </p>
            </section>
            <Button
              title="Already have an Account?"
              onClick={() => navigate("/")}
              className="forgot-password-btn"
            />
          </div>

          <Button
            title="Register"
            className="login-btn"
            loading={loading}
            onClick={handleSubmit}
          />
          <div className="social-login-container">
            <p>— or register with —</p>
            <button
              onClick={useGoogleLogin({
                onSuccess: googleLogin,
                onError: () => {
                  setMessage({
                    type: "error",
                    result: "Google Login Failed",
                  });
                },
              })}
            >
              <img src={googleIcon} className="google-login-img" alt="google" />{" "}
            </button>
          </div>
        </div>
      </div>
      <img src={wave} className="register-wave-img" alt="wave" />
    </div>
  );
}

function FAQ({ question, answer }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={`ind-faq-container ${expanded ? "faq-expanded" : ""}`}>
      <button
        className="ind-faq-question-container"
        onClick={() => setExpanded(!expanded)}
      >
        <p className="ind-faq-question">{question}</p>
        <img src="/arrowIcon.png" className="faq-arrow-icon" alt="arrow-btn" />
      </button>
      <p className="ind-faq-answer">{answer}</p>
    </div>
  );
}
