import { NavLink } from "react-router-dom";
import "./sidebar.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

const logo = "/logo.png";

export function Sidebar() {
  const documentationUrl = process.env.REACT_APP_DOCUMENTATION_URL;

  const pages = [
    {
      title: "Dashboard",
      icon: <HomeOutlinedIcon />,
      path: "/site",
    },
    {
      title: "Settings",
      icon: <SettingsOutlinedIcon />,
      path: "/settings",
    },
    {
      title: "Integration",
      icon: <IntegrationInstructionsOutlinedIcon />,
      path: "/integration",
    },
    {
      title: "Analytics",
      icon: <InsightsOutlinedIcon />,
      path: "/analytics",
    },
    {
      title: "Documentation",
      icon: <ArticleOutlinedIcon />,
      path: () => window.open(documentationUrl, "_blank"),
    },
    {
      title: "My Account",
      icon: <AccountCircleOutlinedIcon />,
      path: "/account",
    },
  ];

  return (
    <main className="sidebar">
      <NavLink to="/site" className="sidebar-logo-container">
        <img src={logo} className="sidebar-logo" alt="logo" />
      </NavLink>
      {pages.map(({ title, icon, path }) => {
        if (typeof path !== "string") {
          return (
            <div className="inactive-sidebar" onClick={path}>
              {icon}
              {title}
            </div>
          );
        }
        return (
          <NavLink
            to={path}
            className={({ isActive }) =>
              isActive ? "active-sidebar" : "inactive-sidebar"
            }
            key={title}
          >
            {icon} {title}
          </NavLink>
        );
      })}
    </main>
  );
}
