import axios from "axios";
import { getItem } from "../../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const createCaptcha = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/recaptcha`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const updateCaptcha = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/recaptcha/${data.id}`,
      method: "PUT",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAllCaptcha = async () => {
  try {
    const response = await axios({
      url: `${base_url}/integration/recaptcha`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getCaptchaById = async (id) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/recaptcha/${id}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteCaptcha = async (id) => {
  try {
    await axios({
      url: `${base_url}/integration/recaptcha/${id}`,
      method: "DELETE",
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export {
  createCaptcha,
  getAllCaptcha,
  getCaptchaById,
  deleteCaptcha,
  updateCaptcha,
};
