import axios from "axios";
import { getItem } from "../../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const createMixPanel = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/mixpanel`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const updateMixPanel = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/mixpanel/${data.id}`,
      method: "PUT",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAllMixPanel = async () => {
  try {
    const response = await axios({
      url: `${base_url}/integration/mixpanel`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getMixPanelById = async (id) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/mixpanel/${id}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getMixPanelCode = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/mixpanel/${data.id}?type=code&domain=${data.domain}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteMixPanel = async (id) => {
  try {
    await axios({
      url: `${base_url}/integration/mixpanel/${id}`,
      method: "DELETE",
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export {
  createMixPanel,
  getAllMixPanel,
  getMixPanelById,
  deleteMixPanel,
  updateMixPanel,
  getMixPanelCode,
};
