import axios from "axios";
import { getItem } from "../../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const createAnalytics = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/ga`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const updateAnalytics = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/ga/${data.id}`,
      method: "PUT",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAllAnalytics = async () => {
  try {
    const response = await axios({
      url: `${base_url}/integration/ga`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAnalyticsById = async (id) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/ga/${id}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAnalyticsCode = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/ga/${data.id}?type=code&domain=${data.domain}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteAnalytics = async (id) => {
  try {
    await axios({
      url: `${base_url}/integration/ga/${id}`,
      method: "DELETE",
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export {
  createAnalytics,
  getAllAnalytics,
  getAnalyticsById,
  deleteAnalytics,
  updateAnalytics,
  getAnalyticsCode,
};
