import "../BlockLibrary/blockLibrary.css";
import { useState, useEffect } from "react";
import { Button, ToggleSwitch } from "../../../Components/Button";
import { CloseOutlined } from "@mui/icons-material";
import {
  CommentField,
  TextField,
} from "../../../Components/TextField/index.js";
import { useDispatch, useSelector } from "react-redux";
import { NotifyUser } from "../../../Components/Notification";
import { formState, getFormFn } from "../../../Redux/formSlice";
import { websiteState } from "../../../Redux/websiteSlice";
import { Dropdown } from "../../../Components/Dropdown";
import { getFormTypes } from "../../../Api/form";
import { createBlock } from "../../../Api/block";

export function FormLibrary({ handleLibrary, formTypes }) {
  const formData = [
    {
      label: "Form Name",
      type: "text",
      variableName: "formName",
    },
    {
      label: "Form Type",
      type: "select",
      variableName: "formType",
      options: formTypes,
    },
    {
      label: "Form Description",
      type: "textArea",
      variableName: "formDescription",
    },
  ];

  const [form] = useState(formData);
  const [enableEmail, setEnableEmail] = useState(false);
  const { pageId, siteId: websiteId } = useSelector(websiteState);
  const { error, loading } = useSelector(formState);
  const [errorMessage, setErrorMessage] = useState("");
  const [input, setInput] = useState({
    formName: "",
    formType: "",
    formDescription: "",
    email: "",
  });
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrorMessage("");
    let temp = { ...input };
    temp[name] = value;
    setInput({ ...temp });
  };

  const validate = () => {
    let errObj = {};

    const getLabel = (name) => {
      const index = formData.findIndex((obj) => obj.variableName === name);
      return formData[index]?.label;
    };

    for (let i in input) {
      if (enableEmail && i === "email" && !input[i]) {
        errObj[i] = `Email is required`;
      } else if (!input[i] && i !== "formDescription" && i !== "email") {
        errObj[i] = `${getLabel(i)} is required`;
      }
    }
    console.log(errObj);
    setErrorMessage(errObj);

    if (Object.keys(errObj).length) {
      return false;
    }
    return true;
  };

  const handleClick = async () => {
    if (!validate()) {
      return;
    }

    let temp = {
      ...input,
      formData: [{ cmsname: "", hubname: "" }],
      formStatus: "",
      emailNotification: enableEmail,
    };

    const response = await createBlock({
      data: temp,
      pageId,
      websiteId,
      type: "forms",
    });

    if (response.error) {
      NotifyUser({ type: "error", message: response.message });
      return;
    }
    NotifyUser({ type: "success", message: "Form Created" });

    dispatch(getFormFn({ pageId, websiteId }));

    handleLibrary();
  };

  return (
    <main className='block-library'>
      <div className='block-library-content'>
        <section className='site-heading-container'>
          <h1>Form</h1>
          <Button
            title={<CloseOutlined />}
            className='navigate-btn'
            onClick={handleLibrary}
          />
        </section>

        <form className='create-block-field'>
          {form.map(({ label, type, variableName, options }, i) => {
            if (type === "text") {
              return (
                <div key={i} className='ind-create-block-field'>
                  <label className='formLabel'>{label}</label>
                  <TextField
                    value={input[variableName]}
                    onChange={handleChange}
                    name={variableName}
                  />

                  <p className='form-err-msg'>{errorMessage?.[variableName]}</p>
                </div>
              );
            } else if (type === "textArea") {
              return (
                <div key={i} className='ind-create-block-field'>
                  <label className='formLabel'>{label}</label>
                  <CommentField
                    value={input[variableName]}
                    onChange={handleChange}
                    name={variableName}
                  />

                  {/* <p className='form-err-msg'>{errorMessage?.[variableName]}</p> */}
                </div>
              );
            } else if (type === "select") {
              return (
                <div key={i} className='ind-create-block-field'>
                  <label className='formLabel'>{label}</label>
                  <Dropdown
                    value={input[variableName]}
                    onChange={handleChange}
                    options={options}
                    name={variableName}
                  />

                  <p className='form-err-msg'>{errorMessage?.[variableName]}</p>
                </div>
              );
            } else {
              return null;
            }
          })}

          <div className='form-library-switch-container'>
            <label className='formLabel'>Email Notification</label>
            <ToggleSwitch
              value={enableEmail}
              onChange={(e) => setEnableEmail(e.target.checked)}
            />
          </div>

          {enableEmail ? (
            <div className='ind-create-block-field'>
              <label className='formLabel'>Email</label>
              <TextField
                value={input["email"]}
                onChange={handleChange}
                name={"email"}
              />
              <p className='form-err-msg'>{errorMessage?.["email"]}</p>
            </div>
          ) : (
            ""
          )}

          {/* {errorMessage ? <p className="form-err-msg">{errorMessage}</p> : ""} */}
          <Button
            title='Create'
            type='button'
            onClick={handleClick}
            className='create-block-btn'
            loading={loading}
          />
        </form>
      </div>
    </main>
  );
}
