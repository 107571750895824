import { notification } from "antd";
// import "../../../node_modules/antd/dist/antd.css";

export const NotifyUser = ({ type, message }) => {
  notification[type]({
    placement: "top",
    bottom: 50,
    duration: 3,
    rtl: true,
    message,
  });
};
