import "./popup.css";
import { CloseOutlined } from "@mui/icons-material";

export function Popup({ children, popup, setPopup, title, className }) {
  return (
    <main className="popupOverlay">
      <div className={`popup-container ${className}`}>
        <section className="popup-heading-container">
          <h2>{title}</h2>
          <button onClick={() => setPopup(false)} className="popup-close-btn">
            <CloseOutlined />
          </button>
        </section>

        {children}
      </div>
    </main>
  );
}
