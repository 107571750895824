import { useSelector } from "react-redux";
import { blockState } from "../../../Redux/blockSlice";
import { Group } from "./Group";

export function NestedGroup({
  nestedListId,
  handleLibrary,
  handleDelete,
  setContentType,
  handleListAdd,
  nestedObj,
}) {
  const { blocks: blockArr } = useSelector(blockState);

  const blockIndex = blockArr.findIndex(({ id }) => id === nestedListId);

  if (blockIndex < 0) {
    return null;
  }

  return (
    <Group
      setContentType={setContentType}
      block={blockArr[blockIndex]}
      handleLibrary={handleLibrary}
      handleDelete={handleDelete}
      nested={true}
    />
  );
}
