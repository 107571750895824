import axios from "axios";
import { getItem } from "../utils/helperFn";

const base_url = process.env.REACT_APP_ANALYTICS_HOST_URL;

const getAnalytics = async (query) => {
  try {
    const response = await axios({
      url: `${base_url}/analytics${query}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAnalyticsFilter = async (query) => {
  try {
    const response = await axios({
      url: `${base_url}/analytics/filter`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export { getAnalytics, getAnalyticsFilter };
