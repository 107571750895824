import "./App.css";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { profileState } from "./Redux/profileSlice";
import Page from "./Pages/SitePages/Page";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Dashboard from "./Pages/Dashboard";
// import Auth from "./Pages/Auth";
import Site from "./Pages/Site";
import SitePages from "./Pages/SitePages";
import Settings from "./Pages/Settings";
import Account from "./Pages/Account";
import Integration from "./Pages/Integration";
import IntegrationPage from "./Pages/Integration/IntegrationPage";
import { Login } from "./Pages/Auth/Login";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import Register from "./Pages/Auth/Register";
import { ForgotPassword } from "./Pages/Auth/ForgotPassword";
import { ResetPassword } from "./Pages/Auth/ResetPassword";
import { SuccessPage } from "./Pages/Auth/SuccessPage";
import { Analytics } from "./Pages/Analytics";

function App() {
  const location = useLocation();
  const analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

  useEffect(() => {
    ReactGA.initialize(analyticsId);
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
  }, [location.pathname, analyticsId]);

  return (
    <Routes>
      <Route
        path="/"
        element={<PrivateRoute children={<Login page="login" />} path="/" />}
      />
      <Route
        path="/signup"
        element={
          <PrivateRoute children={<Register page="signup" />} path="/" />
        }
      />

      <Route
        path="/forgot-password"
        element={<PrivateRoute children={<ForgotPassword />} path="/" />}
      />
      <Route
        path="/reset-password/:id"
        element={<PrivateRoute children={<ResetPassword />} path="/" />}
      />

      <Route
        path="/success/:id"
        element={<PrivateRoute children={<SuccessPage />} path="/" />}
      />

      <Route path="/" element={<Dashboard />}>
        <Route
          path="/site"
          element={<PrivateRoute children={<Site />} path="/site/" />}
        />
        <Route
          path="/settings"
          element={<PrivateRoute children={<Settings />} path="/settings" />}
        />
        <Route
          path="/account"
          element={<PrivateRoute children={<Account />} path="/account" />}
        />
        <Route
          path="/integration"
          element={
            <PrivateRoute children={<Integration />} path="/integration" />
          }
        />
        <Route
          path="/analytics"
          element={<PrivateRoute children={<Analytics />} path="/analytics" />}
        />
        <Route
          path="/integration/:type"
          element={
            <PrivateRoute children={<IntegrationPage />} path="/integration" />
          }
        />
      </Route>
      <Route
        path="/site/:id"
        element={<PrivateRoute children={<SitePages />} path="/site/:id" />}
      />

      <Route
        path="/page/:id"
        element={<PrivateRoute children={<Page />} path="/page" />}
      />
    </Routes>
  );
}

export default App;

function PrivateRoute({ children, path }) {
  const authenticated = useSelector(profileState)?.isLoggedIn;

  if (path === "/") {
    return authenticated ? <Navigate to="/site" /> : children;
  }

  return authenticated ? children : <Navigate to="/" />;
}
