import dracula from "prism-react-renderer/themes/dracula";
import Highlight, { defaultProps } from "prism-react-renderer";
import "./code.css";

export function CodeDisplay({ code }) {
  return (
    <Highlight
      {...defaultProps}
      theme={dracula}
      code={code}
      language="javascript"
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={style}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
}
