import { useState, useEffect } from "react";
import { Button } from "../../../../Components/Button";
import { getAllCloudinaryAccount } from "../../../../Api/Integration/cloudinary";
import { Loader } from "../../../../Components/Loader";
import { Popup } from "../../../../Components/Popup";
import { useNavigate, useSearchParams } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Account } from "./Account";
import { AccountCreate } from "./AccountCreate";
import { CloudinaryAccount } from "./CloudinaryAccount";

export function Cloudinary() {
  const [showPopup, setShowPopup] = useState(false);
  const [credentials, setCredentials] = useState("");
  const [loading, setLoading] = useState(false);
  const [popupType, setPopupType] = useState("create");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const accountId = searchParams.get("account");

  const fetchCredentials = async () => {
    setLoading(true);
    const response = await getAllCloudinaryAccount();
    setCredentials(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchCredentials();
  }, []);

  const handleEdit = (e, id) => {
    e.stopPropagation();
    const filterSite = credentials.filter((obj) => obj.id === id);
    if (!filterSite[0]) {
      return;
    }
    setPopupType("update");
    setSelectedAccount(filterSite[0]);
    setShowPopup(true);
  };

  return !accountId ? (
    <main className="ind-integration-page">
      {showPopup ? (
        <Popup
          children={
            <AccountCreate
              fetchCredentials={fetchCredentials}
              selectedAccount={selectedAccount}
              popupType={popupType}
              setShowPopup={setShowPopup}
            />
          }
          popup={showPopup}
          setPopup={setShowPopup}
          title="Cloudinary Account"
          // className="integration-form-popup"
        />
      ) : (
        ""
      )}
      <section className="site-heading-container">
        <h1>
          {" "}
          <Button
            title={<KeyboardBackspaceOutlinedIcon />}
            className="navigate-btn"
            onClick={() => navigate(-1)}
          />
          Cloudinary
        </h1>
        {credentials && !credentials.length ? (
          <Button
            title="+ Add Credential"
            onClick={() => {
              setPopupType("create");
              setShowPopup(true);
            }}
          />
        ) : (
          ""
        )}
      </section>
      <section className="site-list-container">
        {credentials && credentials.length ? (
          credentials.map((obj, i) => {
            return (
              <Account
                obj={obj}
                key={i}
                handleEdit={handleEdit}
                fetchCredentials={fetchCredentials}
              />
            );
          })
        ) : credentials && !loading ? (
          <section className="message-container">
            <p>No Accounts Found</p>
          </section>
        ) : (
          <section className="message-container">
            <Loader />
          </section>
        )}
      </section>
    </main>
  ) : (
    <CloudinaryAccount accountId={accountId} />
  );
}
