import { NotifyUser } from "../../../../Components/Notification/index.js";
import { useState } from "react";
import { CustomDropdown } from "../../../../Components/Dropdown/customDropdown";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { deleteMixPanel } from "../../../../Api/Integration/mixpanel";

export function Site({ obj, fetchSiteData, handleEdit, handleClick }) {
  const { name, id, domain } = obj;

  const [showOption, setShowOption] = useState(false);

  const handleMoreInfo = (e) => {
    // e.stopPropagation();
    setShowOption(true);
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation();
    const response = await deleteMixPanel(id);
    if (!response?.error) {
      fetchSiteData();
      NotifyUser({
        type: "success",
        message: `Website Deleted`,
      });
    }
  };

  const menu = [
    {
      title: "Edit",
      callBack: (e, id) => handleEdit(e, id),
    },
    {
      title: "Delete",
      callBack: (e, id) => handleDelete(e, id),
    },
    {
      title: "View Code",
      callBack: (e) => handleClick({ domain, id }),
    },
  ];
  return (
    <div key={id} className="ind-integration">
      <section className="recaptcha-site-details">
        <section>
          <p>{name} </p>
        </section>

        <button onClick={handleMoreInfo}>
          <MoreVertOutlinedIcon />
        </button>
        {showOption ? (
          <CustomDropdown
            className="custom-dropdown-container-integration"
            showOption={showOption}
            setShowOption={setShowOption}
            menu={menu}
            id={id}
          />
        ) : (
          ""
        )}
      </section>
    </div>
  );
}
