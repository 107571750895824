import { useState, useEffect } from "react";
import { Button } from "../../../../Components/Button";
import { Loader } from "../../../../Components/Loader";
import { NotifyUser } from "../../../../Components/Notification";
import { Popup } from "../../../../Components/Popup";
import { useNavigate, useSearchParams } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import {
  sheetsApi,
  getAllSheetsById,
} from "../../../../Api/Integration/googlesheets";
import { CreateSheet } from "./CreateSheet";
import { Sheet } from "./Sheet";

export function SheetList() {
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get("account");
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("create");
  const [sheetDetails, setSheetDetails] = useState("");
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState("");
  const navigate = useNavigate();

  const fetchSheets = async () => {
    const response = await getAllSheetsById(accountId);
    setLoading(true);
    if (response.error) {
      NotifyUser({ type: "error", message: response.message });
      setLoading(false);
      return;
    }
    setSheetDetails(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchSheets();
    // eslint-disable-next-line
  }, []);

  const handleEdit = (id) => {
    const filterSheet = sheetDetails.filter((obj) => obj.id === id);
    if (filterSheet && filterSheet.length) {
      setSelectedSpreadsheet(filterSheet[0]);
      setShowPopup(true);
      setPopupType("edit");
    }
  };

  const handleDelete = async (id) => {
    const filterSheet = sheetDetails.filter((obj) => obj.id === id);
    if (filterSheet && filterSheet.length) {
      const obj = {
        credsId: filterSheet?.[0]?._id,
        remove: true,
        id: accountId,
      };
      const response = await sheetsApi(obj);
      if (response.error) {
        NotifyUser({ type: "error", message: response.message });
      } else {
        fetchSheets();
        NotifyUser({ type: "success", message: "Api Deleted" });
      }
    }
  };

  return (
    <main className="ind-integration-page">
      {showPopup ? (
        <Popup
          children={
            <CreateSheet
              fetchSheets={fetchSheets}
              selectedSpreadsheet={selectedSpreadsheet}
              popupType={popupType}
              setShowPopup={setShowPopup}
            />
          }
          popup={showPopup}
          setPopup={setShowPopup}
          title="Google Sheets"
          className="integration-sheetList-popup"
        />
      ) : (
        ""
      )}
      <section className="site-heading-container">
        <h1>
          {" "}
          <Button
            title={<KeyboardBackspaceOutlinedIcon />}
            className="navigate-btn"
            onClick={() => navigate(-1)}
          />
          GoogleSheets
        </h1>

        <Button
          title="+ Add Spreadsheet"
          onClick={() => {
            setPopupType("create");
            setShowPopup(true);
          }}
        />
      </section>

      <section className="integration-selected-sheets-container">
        {sheetDetails && Array.isArray(sheetDetails) && sheetDetails?.length ? (
          sheetDetails?.map((obj, i) => {
            return (
              <Sheet
                obj={obj}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                key={i}
              />
            );
          })
        ) : sheetDetails && !loading ? (
          <section className="message-container">
            <p>Spreadsheets not Found</p>
          </section>
        ) : (
          <section className="message-container">
            <Loader />
          </section>
        )}
      </section>
    </main>
  );
}
