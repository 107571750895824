import axios from "axios";
import { getItem } from "../../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const createClarity = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/clarity`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const updateClarity = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/clarity/${data.id}`,
      method: "PUT",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAllClarity = async () => {
  try {
    const response = await axios({
      url: `${base_url}/integration/clarity`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getClarityById = async (id) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/clarity/${id}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getClarityCode = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/clarity/${data.id}?type=code&domain=${data.domain}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteClarity = async (id) => {
  try {
    await axios({
      url: `${base_url}/integration/clarity/${id}`,
      method: "DELETE",
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export {
  createClarity,
  getAllClarity,
  getClarityById,
  deleteClarity,
  updateClarity,
  getClarityCode,
};
