import { LoadingOutlined } from "@ant-design/icons";
import Switch from "@mui/material/Switch";
import "./button.css";

export function Button({
  title,
  className,
  onClick,
  disabled,
  loading,
  type,
  icon,
}) {
  return (
    <button
      className={`${className} btnStyle`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {icon ? icon : ""}
      {title}
      {loading ? <LoadingOutlined spin delay={500} /> : ""}
    </button>
  );
}

export function ToggleSwitch({ value, onChange }) {
  return (
    <Switch
      checked={value}
      onChange={onChange}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
}
