import { useState } from "react";
import { TextField } from "../../Components/TextField";
import { imgObj } from "../../utils/image";
import { Button } from "../../Components/Button";
import { useNavigate } from "react-router-dom";
import { emailValidation } from "../../utils/helperFn";
import { forgotpasswordApi } from "../../Api/auth";

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
    setMessage("");
  };

  const handleSubmit = async () => {
    setMessage("");
    if (!email) {
      setMessage({ type: "error", result: "Email is required" });
      return;
    }

    const validation = emailValidation(email);

    if (!validation) {
      setMessage({ type: "error", result: "Invalid Email" });
      return;
    }
    setLoading(true);
    const result = await forgotpasswordApi({ email });

    if (result.error) {
      setMessage({ type: "error", result: result.message });
      setLoading(false);
      return;
    }
    setLoading(false);
    setMessage({
      type: "success",
      result: "Password reset link sent to your email address.",
    });
  };

  return (
    <div className="forgotpassword-page">
      <div className="forgotpassword-container">
        <div class="login-img-container">
          <img src={imgObj.logo} class="auth-login-logo" alt="logo" />
          <img
            src={imgObj.forgotPassword1}
            class="forgot-password-illustration-img"
            alt="illustration"
          />
        </div>

        <div className="login-content-container">
          <div>
            <p className="login-heading">Forgot Password</p>
            <p className="login-description">
              Please enter your email address to request a password reset.
            </p>
          </div>
          <br />
          <div className="login-field-container">
            <TextField
              name="email"
              onChange={handleChange}
              value={email}
              className="login-field-email"
              placeholder="Email"
              autoFocus={true}
            />
            <div className="field-divider" />

            <p
              className={
                message?.type === "success"
                  ? "login-success-message"
                  : "login-err-message"
              }
            >
              {message?.result}
            </p>
          </div>
          <Button
            title="Send"
            // disabled={disabled}
            className="login-btn"
            loading={loading}
            onClick={handleSubmit}
          />

          <div className="forgot-password-info-container">
            <p>Remember your password? </p>
            <Button
              onClick={() => navigate("/")}
              title="Login"
              className="forgot-password-login-btn"
            />
          </div>
        </div>
      </div>
      <img src={imgObj.wave} class="login-wave-img" alt="wave" />
    </div>
  );
}
