import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Loader } from "../../../Components/Loader";
import { CodeDisplay } from "../../../Components/Code";
import { getBlockResponse } from "../../../Api/block";
import { NotifyUser } from "../../../Components/Notification";
import { blockState } from "../../../Redux/blockSlice";

export function BlockResponse() {
  const [code, setCode] = useState("");
  const { blocks } = useSelector(blockState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getResponse = async () => {
      setLoading(true);
      const response = await getBlockResponse({ blocks });
      if (response.error) {
        NotifyUser({ type: "error", message: response.message });
        return;
      }
      setCode(response);
      setLoading(false);
    };
    getResponse();
  }, [blocks]);

  return (
    <main className="block-response-container">
      {code ? (
        <CodeDisplay code={JSON.stringify(code, null, 2)} />
      ) : !code && !loading ? (
        <section>
          <p>Response Generation Failed</p>
        </section>
      ) : (
        <section>
          <Loader />
        </section>
      )}
    </main>
  );
}
