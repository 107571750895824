import axios from "axios";
import { getItem } from "../../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const getAllGoogleAccounts = async () => {
  try {
    const response = await axios({
      url: `${base_url}/integration/googleSheets/user`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAllSheetsById = async (id) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/googleSheets/user/${id}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteGoogleAccount = async (id) => {
  try {
    await axios({
      url: `${base_url}/integration/googleSheets/user/${id}`,
      method: "DELETE",
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const sheetsApi = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/googleSheets/info`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });
    return { error: false, response: response?.data?.data };
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export {
  getAllGoogleAccounts,
  deleteGoogleAccount,
  sheetsApi,
  getAllSheetsById,
};
