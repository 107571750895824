import axios from "axios";
import { getItem } from "../../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const createCloudinaryAccount = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/cloudinary`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const updateCloudinaryAccount = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/cloudinary`,
      method: "PUT",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAllCloudinaryAccount = async () => {
  try {
    const response = await axios({
      url: `${base_url}/integration/cloudinary/all`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getCloudinaryAccount = async () => {
  try {
    const response = await axios({
      url: `${base_url}/integration/cloudinary`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteCloudinaryAccount = async (id) => {
  try {
    await axios({
      url: `${base_url}/integration/cloudinary`,
      method: "DELETE",
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const uploadImageCloudinaryAccount = async ({ data }) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/cloudinary/upload`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error?.response?.data.message };
  }
};

const getCloudinaryImages = async ({ query, cursor }) => {
  try {
    const response = await axios({
      url: !query
        ? `${base_url}/integration/cloudinary/image?next_cursor=${" "}`
        : `${base_url}/integration/cloudinary/image?next_cursor=${
            cursor === undefined ? "" : cursor
          }${query}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteCloudinaryImages = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/cloudinary/images`,
      method: "DELETE",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error?.response?.data.message };
  }
};

export {
  createCloudinaryAccount,
  getAllCloudinaryAccount,
  getCloudinaryAccount,
  deleteCloudinaryAccount,
  updateCloudinaryAccount,
  uploadImageCloudinaryAccount,
  getCloudinaryImages,
  deleteCloudinaryImages,
};
