import { useState, useEffect, useRef } from "react";
import { Button } from "../../../Components/Button";
import { Loader } from "../../../Components/Loader";
import { NotifyUser } from "../../../Components/Notification";
import { Popup } from "../../../Components/Popup";
import { useNavigate, useSearchParams } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { CustomDropdown } from "../../../Components/Dropdown/customDropdown";
import { Dropdown } from "../../../Components/Dropdown";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { io } from "socket.io-client";
import { getItem } from "../../../utils/helperFn";
import {
  getAllHubspotAccounts,
  deleteHubspotAccount,
} from "../../../Api/Integration/hubspot";
import { IDTooltip } from "../../../Components/Tooltip/Idtooltip";

export function Hubspot() {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get("account");
  const token = getItem("token");

  // Socket
  const [socketId, setSocketId] = useState("");
  const [myWindow, setmyWindow] = useState("");
  const [socketMessage, setSocketMessage] = useState("");
  const hostUrl = process.env.REACT_APP_HOST_URL;
  const redirectUrl = `${hostUrl}/integration/hubspot/oauth?token=${token}&socketId=${socketId}`;

  useEffect(() => {
    const socket = io(hostUrl);

    socket.on("connect", () => {
      console.log("socket-connected", socket.id);
      setSocketId(socket.id);
    });

    socket.on("message", (serverMessage) => {
      setSocketMessage(serverMessage);
    });

    return () => socket.disconnect();
  }, [hostUrl]);

  var val = useRef("");

  function open() {
    if (socketMessage.message === "progress") {
      val.current = window.open(`${redirectUrl}`, "", "width=500,height=500");

      setmyWindow(val.current);
    }
    if (socketMessage.message === "Integration Successful") {
      myWindow.close();

      NotifyUser({
        message: "Authentication Successful",
        type: "success",
      });
      fetchAccounts();
      setSocketMessage("");
    }
    if (socketMessage.message === "Integration Unsuccessful") {
      myWindow.close();
      NotifyUser({
        message: "Authentication Failed",
        type: "error",
      });
    }
  }
  // eslint-disable-next-line
  useEffect(open, [socketMessage, redirectUrl, socketId]);

  console.log(socketMessage);

  useEffect(() => {
    const checkPopup = setInterval(() => {
      if (val.current) {
        if (val.current.closed) {
          clearInterval(checkPopup);
          if (socketMessage && socketMessage.message === "progress") {
            setSocketMessage("");
            NotifyUser({
              message: "Error Occurred, Try Again",
              type: "error",
            });
          }
        }
      }
    }, 500);
    // eslint-disable-next-line
  }, [val]);

  const fetchAccounts = async () => {
    setLoading(true);
    const response = await getAllHubspotAccounts();
    setAccounts(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <main className="ind-integration-page">
      <section className="site-heading-container">
        <h1>
          {" "}
          <Button
            title={<KeyboardBackspaceOutlinedIcon />}
            className="navigate-btn"
            onClick={() => navigate(-1)}
          />
          Hubspot
        </h1>
        {accounts && !accounts.length ? (
          <Button
            title="+ Add Account"
            onClick={() => {
              setSocketMessage({ message: "progress" });
            }}
          />
        ) : (
          ""
        )}
      </section>
      <section className="site-list-container">
        {accounts && Array.isArray(accounts) && accounts?.length ? (
          accounts?.map((obj, i) => {
            return <Account obj={obj} key={i} fetchAccounts={fetchAccounts} />;
          })
        ) : accounts && !loading ? (
          <section className="message-container">
            <p>No Accounts Found</p>
          </section>
        ) : (
          <section className="message-container">
            <Loader />
          </section>
        )}
      </section>
    </main>
  );
}

function Account({ obj, fetchAccounts }) {
  const { name, id } = obj;

  const [showOption, setShowOption] = useState(false);
  const navigate = useNavigate();
  const handleMoreInfo = (e) => {
    e.stopPropagation();
    setShowOption(true);
  };

  const handleClick = () => {
    navigate(`/integration/hubspot?account=${id}`);
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation();
    const response = await deleteHubspotAccount(id);
    if (!response?.error) {
      fetchAccounts();
      NotifyUser({
        type: "success",
        message: `Account Deleted`,
      });
    }
  };

  const menu = [
    {
      title: "Delete",
      callBack: (e, id) => handleDelete(e, id),
    },
  ];
  return (
    <div key={id} className="ind-integration" onClick={handleClick}>
      <section className="recaptcha-site-details">
        {/* <section> */}
        <p>
          {name} <IDTooltip value={id} title="Credential ID" />{" "}
        </p>
        {/* <p>{id} </p> */}
        {/* </section> */}

        <button onClick={handleMoreInfo}>
          <MoreVertOutlinedIcon />
        </button>
        {showOption ? (
          <CustomDropdown
            className="custom-dropdown-container-integration"
            showOption={showOption}
            setShowOption={setShowOption}
            menu={menu}
            id={id}
          />
        ) : (
          ""
        )}
      </section>
    </div>
  );
}
