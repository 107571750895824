import { useEffect, useState } from "react";
import "./integration.css";
import { useNavigate } from "react-router-dom";
import { getIntegrationList } from "../../Api/Integration/integration";
import { Loader } from "../../Components/Loader";
import { NotifyUser } from "../../Components/Notification";

const recaptcha = "/reCAPTCHA-logo.png";
const cloudinary = "/cloudinary-logo.png";
const analytics = "/analytics-logo.png";
const googleSheets = "/googlesheets-logo.png";
const hubspot = "/hubspot.png";
const cors = "/cors-logo.png";
const waitlist = "/waitlist.png";
const clarity = "/clarity.png";
const mixpanel = "/mixpanel.png";
const mail = "/mail.png";
const auth = "/auth.png";

export default function Integration() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [integrationList, setIntegrationList] = useState("");

  useEffect(() => {
    const getList = async () => {
      setLoading(true);
      const response = await getIntegrationList();

      if (response.error) {
        setLoading(false);
        NotifyUser({ type: "error", message: response.message });

        return;
      }
      setIntegrationList([...response?.integrations]);
      setLoading(false);

      console.log(response);
    };
    getList();
  }, []);

  const integrationPathList = [
    {
      name: "Google reCAPTCHA",
      icon: recaptcha,
    },
    {
      name: "Cloudinary",
      icon: cloudinary,
    },
    {
      name: "Analytics",
      icon: analytics,
    },
    {
      name: "Google Sheets",
      icon: googleSheets,
    },
    {
      name: "Hubspot",
      icon: hubspot,
    },
    {
      name: "Cors",
      icon: cors,
    },
    {
      name: "Mixpanel",
      icon: mixpanel,
    },
    {
      name: "Clarity",
      icon: clarity,
    },
    {
      name: "Waitlist",
      icon: waitlist,
    },
    {
      name: "Email Verification",
      icon: mail,
    },
    {
      name: "Authentication",
      icon: auth,
    },
  ];

  const getIcon = (name) => {
    try {
      let index = integrationPathList.findIndex((obj) => obj.name === name);
      return integrationPathList[index]?.icon;
    } catch (error) {
      return "";
    }
  };

  const handleNavigate = (name) => {
    try {
      let path = name.toLowerCase().split(" ").join("-");

      navigate(path);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main className='integration-page'>
      <section>
        <h1>Integration</h1>
      </section>
      <div className='integration-list'>
        {integrationList && integrationList.length ? (
          integrationList.map(({ metadata, name }, i) => {
            if (metadata && Object.keys(metadata).length) {
              return (
                <div
                  className='ind-integration'
                  key={i}
                  onClick={() => handleNavigate(name)}
                >
                  <img src={getIcon(name)} title={name} alt='logo' />
                  <p>{name}</p>
                  {/* <section className="integration-list-meta-container">
                    <p>Emails Used : {metadata?.emailUsed}</p>
                    <p>Emails Available : {metadata?.emailAvailable}</p>
                  </section> */}
                </div>
              );
            }
            return (
              <div
                className='ind-integration'
                key={i}
                onClick={() => handleNavigate(name)}
              >
                <img src={getIcon(name)} title={name} alt='logo' />
                <p>{name}</p>
              </div>
            );
          })
        ) : integrationList && !loading && !integrationList.length ? (
          <section className='message-container'>No Integration Found</section>
        ) : (
          <section className='message-container'>
            <Loader />
          </section>
        )}
      </div>
    </main>
  );
}
