import { createSlice } from "@reduxjs/toolkit";

const listSlice = createSlice({
  name: "List",
  initialState: { list: {}, listId: "" },
  reducers: {
    currentListFn: (state, action) => {
      console.log(action.payload);
      state.list = { ...action.payload.list };
      state.listId = action.payload.listId;
    },
  },
});

const listReducer = listSlice.reducer;
const listState = (state) => state.lists;
const { currentListFn } = listSlice.actions;

export { listReducer, listState, currentListFn };
