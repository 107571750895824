import { useState } from "react";
import { NotifyUser } from "../../../../Components/Notification";
import { useNavigate } from "react-router-dom";
import { CustomDropdown } from "../../../../Components/Dropdown/customDropdown";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { deleteGoogleAccount } from "../../../../Api/Integration/googlesheets";

export function Account({ obj, fetchAccounts }) {
  const { email, id } = obj;

  const [showOption, setShowOption] = useState(false);
  const navigate = useNavigate();
  const handleMoreInfo = (e) => {
    e.stopPropagation();
    setShowOption(true);
  };

  const handleClick = () => {
    navigate(`/integration/google-sheets?account=${id}`);
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation();
    const response = await deleteGoogleAccount(id);
    if (!response?.error) {
      fetchAccounts();
      NotifyUser({
        type: "success",
        message: `Account Deleted`,
      });
    }
  };

  const menu = [
    {
      title: "Delete",
      callBack: (e, id) => handleDelete(e, id),
    },
  ];
  return (
    <div key={id} className="ind-integration" onClick={handleClick}>
      <section className="recaptcha-site-details">
        {/* <section> */}
        <p>{email} </p>
        {/* <p>{id} </p> */}
        {/* </section> */}

        <button onClick={handleMoreInfo}>
          <MoreVertOutlinedIcon />
        </button>
        {showOption ? (
          <CustomDropdown
            className="custom-dropdown-container-integration"
            showOption={showOption}
            setShowOption={setShowOption}
            menu={menu}
            id={id}
          />
        ) : (
          ""
        )}
      </section>
    </div>
  );
}
