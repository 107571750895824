import axios from "axios";
import { getItem } from "../../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const getIntegrationList = async () => {
  try {
    const response = await axios({
      url: `${base_url}/integration/list`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });

    return response.data.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export { getIntegrationList };
