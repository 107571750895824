import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import { Select } from "antd";
import "./paginate.css";

export function Paginate({ className, getDetails, pagination, setData }) {
  const { limit: Limit, page, totalPages, totalResults } = pagination;
  const [limit, setLimit] = useState(Limit);
  const [, setCurrentPage] = useState(page);
  const [pageNumber, setPageNumber] = useState([]);

  useEffect(() => {
    setLimit(Limit);
  }, [Limit]);

  useEffect(() => {
    let arr = [];
    let count = 0;

    if (totalResults <= 10) {
      count = 3;
    } else if (totalResults >= 11 && totalResults <= 21) {
      count = 5;
    } else if (totalResults >= 22 && totalResults <= 29) {
      count = 5;
    } else if (totalResults > 29) {
      count = 10;
    }
    for (let i = count; i <= totalResults; i += count) {
      arr.push(i);
    }

    setPageNumber(arr);
  }, [totalResults]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    getDetails(`?page=${pageNumber}&limit=${limit}`);
    // setData("");
  };

  const handleClick = (val) => {
    setLimit(val);
    getDetails(`?page=${1}&limit=${val}`);
    // setData("");
  };

  return (
    <div className={className}>
      {/* {pageNumber && pageNumber.length ? (
        <div className="pageList-dropdown">
          <Select
            showSearch={false}
            className="pageList-value-container"
            value={limit === totalResults ? "All" : limit}
            showArrow={true}
            bordered={false}
            size="middle"
            filterOption={false}
            options={pageNumber.map((data) => ({ value: data, label: data }))}
            onChange={handleClick}
          />
          <p className="pageList-heading">Items Per Page</p>
        </div>
      ) : (
        ""
      )} */}

      <Stack spacing={2}>
        <Pagination
          count={totalPages}
          color="primary"
          variant="outlined"
          onChange={(e, value) => paginate(value)}
          shape="rounded"
        />
      </Stack>
    </div>
  );
}
