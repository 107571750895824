import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useState } from "react";
import "./textfield.css";

export function TextField({
  type,
  placeholder,
  value,
  name,
  onChange,
  onBlur,
  readOnly,
  onKeyDown,
  onKeyUp,
  autoFocus,
  className = "textField",
}) {
  return (
    <input
      className={className}
      name={name}
      type={type}
      readOnly={readOnly}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      autoFocus={autoFocus}
    />
  );
}

export function CommentField({
  type,
  placeholder,
  value,
  name,
  onChange,
  onBlur,
  readOnly,
  onKeyDown,
  className = "commentTextField",
}) {
  return (
    <textarea
      className={className}
      name={name}
      type={type}
      readOnly={readOnly}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
}

export function HiddenField({
  type,
  placeholder,
  value,
  name,
  onChange,
  onBlur,
  readOnly,
  onKeyDown,
  autoFocus,
  className = "textField",
}) {
  const [inputType, setInputType] = useState("password");

  const passwordBtn =
    inputType === "password" ? (
      <VisibilityOutlinedIcon />
    ) : (
      <VisibilityOffOutlinedIcon />
    );

  return (
    <section className="hiddenField-container">
      <input
        className={className}
        name={name}
        type={inputType}
        readOnly={readOnly}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
      />
      <span
        onClick={() => setInputType(inputType === "text" ? "password" : "text")}
      >
        {passwordBtn}
      </span>
    </section>
  );
}
