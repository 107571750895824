import axios from "axios";
import { getItem } from "../../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const createAuthIntegration = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/auth`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const updateAuthIntegration = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/auth/${data.id}`,
      method: "PUT",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAllAuthIntegration = async () => {
  try {
    const response = await axios({
      url: `${base_url}/integration/auth`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAllAuthUsers = async (id) => {
  try {
    const response = await axios({
      url: `${base_url}/integration/auth/user/${id}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteAuthIntegration = async (id) => {
  try {
    await axios({
      url: `${base_url}/integration/auth/${id}`,
      method: "DELETE",
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export {
  createAuthIntegration,
  getAllAuthIntegration,
  deleteAuthIntegration,
  updateAuthIntegration,
  getAllAuthUsers,
};
