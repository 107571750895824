import { useState, useEffect } from "react";
import { Button } from "../../../../Components/Button";
import { Loader } from "../../../../Components/Loader";
import { NotifyUser } from "../../../../Components/Notification";
import { Popup } from "../../../../Components/Popup";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { corsApi } from "../../../../Api/Integration/cors";
import { CreateCorsApi } from "./CreateCorsApi";
import { CorsDetail } from "./CorsDetail";

export function Cors() {
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("create");
  const [corsDetails, setCorsDetails] = useState("");
  const [selectedCorsDetail, setSelectedCorsDetail] = useState("");
  const navigate = useNavigate();

  const fetchCorsDetails = async () => {
    const response = await corsApi({ method: "GET" });
    setLoading(true);
    if (response.error) {
      NotifyUser({ type: "error", message: response.message });
      setLoading(false);
      return;
    }

    setCorsDetails(response.result);
    setLoading(false);
  };

  useEffect(() => {
    fetchCorsDetails();
    // eslint-disable-next-line
  }, []);

  const handleEdit = (id) => {
    const filterCorsDetails = corsDetails.filter((obj) => obj.id === id);
    if (filterCorsDetails && filterCorsDetails.length) {
      setSelectedCorsDetail(filterCorsDetails[0]);
      setShowPopup(true);
      setPopupType("edit");
    }
  };

  const handleDelete = async (id) => {
    const response = await corsApi({ method: "DELETE", id });
    if (response.error) {
      NotifyUser({ type: "error", message: response.message });
    } else {
      fetchCorsDetails();
      NotifyUser({ type: "success", message: "Api Deleted" });
    }
  };

  return (
    <main className="ind-integration-page">
      {showPopup ? (
        <Popup
          children={
            <CreateCorsApi
              fetchCorsDetails={fetchCorsDetails}
              selectedCorsDetail={selectedCorsDetail}
              popupType={popupType}
              setShowPopup={setShowPopup}
            />
          }
          popup={showPopup}
          setPopup={setShowPopup}
          title="Api Details"
          className="integration-sheetList-popup"
        />
      ) : (
        ""
      )}
      <section className="site-heading-container">
        <h1>
          {" "}
          <Button
            title={<KeyboardBackspaceOutlinedIcon />}
            className="navigate-btn"
            onClick={() => navigate(-1)}
          />
          Cors
        </h1>

        <Button
          title="+ Add Api"
          onClick={() => {
            setPopupType("create");
            setSelectedCorsDetail("");
            setShowPopup(true);
          }}
        />
      </section>

      <section className="integration-selected-sheets-container">
        {corsDetails && Array.isArray(corsDetails) && corsDetails?.length ? (
          corsDetails?.map((obj, i) => {
            return (
              <CorsDetail
                obj={obj}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                key={i}
              />
            );
          })
        ) : corsDetails && !loading ? (
          <section className="message-container">
            <p>Api not Found</p>
          </section>
        ) : (
          <section className="message-container">
            <Loader />
          </section>
        )}
      </section>
    </main>
  );
}
