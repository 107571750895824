import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createBlock, deleteBlock, updateBlock } from "../Api/block";
import { getAllForms } from "../Api/form";
import { NotifyUser } from "../Components/Notification";

const deleteFormFn = createAsyncThunk(
  "data/deleteForm",
  async (obj, thunkAPI) => {
    const response = await deleteBlock({
      ...obj,
      blockId: obj.formId,
      type: "forms",
    });
    return response?.forms;
  }
);

const getFormFn = createAsyncThunk("data/getForm", async (obj, thunkAPI) => {
  const response = await getAllForms(obj);
  return response?.forms;
});

const formSlice = createSlice({
  name: "Form",
  initialState: { error: false, loading: false, forms: [] },
  reducers: {
    updateFormContentFn: (state, action) => {
      const index = state.forms.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (index >= 0) {
        state.forms[index] = action.payload;
      }
    },

    updateFormFn: (state, action) => {
      const tempForm = [...state.forms];
      const { formId } = action.payload;
      tempForm.map((obj) => {
        if (obj.id === formId) {
          obj.formData.push({ cmsname: "", hubName: "" });
        }
        return obj;
      });
    },
    deleteFormFn: (state, action) => {
      const filterData = state.forms.filter(
        (obj) => obj.id !== action.payload.formId
      );
      state.forms = filterData;
    },

    deleteFormFieldFn: (state, action) => {
      const { formId, index } = action.payload;
      let temp = [...state.forms];
      temp.map((obj) => {
        if (obj.id === formId) {
          obj.formData = obj.formData.filter((data, i) => i !== index);
        }
        return obj;
      });
      state.forms = [...temp];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFormFn.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getFormFn.fulfilled, (state, action) => {
      state.loading = false;
      state.forms = action.payload;
    });
    builder.addCase(getFormFn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteFormFn.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteFormFn.fulfilled, (state, action) => {
      state.loading = false;
      state.forms = action.payload;
    });
    builder.addCase(deleteFormFn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

const formReducer = formSlice.reducer;
const formState = (state) => state.forms;
const {
  updateFormFn,
  updateFormContentFn,
  deleteFormFieldFn,
  //   deleteFormFn,
} = formSlice.actions;

export {
  updateFormFn,
  updateFormContentFn,
  deleteFormFieldFn,
  deleteFormFn,
  formReducer,
  formState,
  getFormFn,
};
