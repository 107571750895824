import DateObject from "react-date-object";
import { v4 as uuidv4 } from "uuid";

const uuid = () => uuidv4();

const getItem = (key) => {
  const data = localStorage.getItem(key);
  return JSON.parse(data);
};

const setItem = ({ key, value }) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

const removeAllItem = () => {
  return localStorage.clear();
};

const dateFormatter = ({ date, format }) => {
  const result = new DateObject(date);
  return result.format(format);
};

const dataUrlConverter = (file) => {
  try {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  } catch (error) {
    return;
  }
};

const emailValidation = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
};

const urlValidation = (url) => {
  const pattern =
    /^(?:(?:(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)|(?:localhost)|(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?::[0-9]{1,5})?)$/;
  return pattern.test(url);
};

const fileDownload = ({ fileType, fileName, data, contentType }) => {
  try {
    const blob = new Blob([data], {
      type: contentType,
    });
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}${fileType}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 B";

  const units = ["B", "KB", "MB", "GB", "TB"];
  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${units[i]}`;
};

export {
  getItem,
  setItem,
  removeAllItem,
  dateFormatter,
  uuid,
  dataUrlConverter,
  emailValidation,
  urlValidation,
  fileDownload,
  formatFileSize,
};
