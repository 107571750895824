import { Outlet } from "react-router-dom";
import { Sidebar } from "../../Components/Sidebar";
import "./dashboard.css";

export default function Dashboard() {
  return (
    <>
      <Sidebar />
      <Outlet />
    </>
  );
}
