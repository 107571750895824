import axios from "axios";
import { setItem, getItem } from "../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const authApi = async ({ data, type }) => {
  try {
    const response = await axios({
      url: `${base_url}/auth/${type}`,
      method: "POST",
      data,
    });

    const { tokens, user } = response.data;
    setItem({ key: "token", value: tokens });
    return { response: user };
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const updateProfile = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/user`,
      method: "PUT",
      data,
      headers: { Authorization: getItem("token") },
    });

    return response.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const forgotpasswordApi = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/auth/forgot-password`,
      method: "POST",
      data,
    });

    return { error: false, response: response.data };
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const resetpasswordApi = async ({ data, token }) => {
  try {
    const response = await axios({
      url: `${base_url}/auth/reset-password?token=${token}`,
      method: "POST",
      data,
    });

    return { error: false, response: response.data };
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export { authApi, updateProfile, forgotpasswordApi, resetpasswordApi };
