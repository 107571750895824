import { Button } from "../../../Components/Button";
import { useDispatch } from "react-redux";
import {
  CommentField,
  TextField,
} from "../../../Components/TextField/index.js";
import { RichTextEditor } from "../../../Components/TextField/RichTextEditor";
import { currentListFn } from "../../../Redux/listSlice";
import { DeleteOutlined } from "@mui/icons-material";
import { updateBlockContentFn } from "../../../Redux/blockSlice";
import { Link } from "./Link";

export function Group({
  block,
  setContentType,
  handleLibrary,
  handleDelete,
  nested,
}) {
  const dispatch = useDispatch();
  const { blockData: listDetails, blockName, id } = block;

  const handleAdd = () => {
    dispatch(currentListFn({ list: { ...block }, listId: id }));
    setContentType("list");
    handleLibrary();
  };

  const handleChange = (e, id) => {
    let { name, value } = e.target;

    const tempObj = JSON.parse(JSON.stringify({ ...listDetails }));
    tempObj[id].blockData = { [name]: value };

    let obj = { ...block };
    obj.blockData = { ...tempObj };

    dispatch(updateBlockContentFn(obj));
  };

  const getBlockValue = ({ variableName, id }) => {
    let outerObj = JSON.parse(JSON.stringify({ ...listDetails }));
    return outerObj?.[id]?.blockData?.[variableName];
  };

  return (
    <div
      className={`list-field-container ${nested ? "nested-grp-container" : ""}`}
      key={id}
    >
      <section className='formLabel list-label'>
        {blockName}{" "}
        <button onClick={() => handleDelete(id)}>
          <DeleteOutlined />
        </button>
      </section>
      {listDetails && Object.keys(listDetails).length ? (
        <>
          {Object.entries(listDetails).map(([key, values]) => {
            if (typeof values === "string") {
              return null;
            }
            let { fieldName, fieldType, id } = values;
            if (fieldType === "textArea") {
              return (
                <div className='block-field' key={id}>
                  <label className='formLabel'>{fieldName}</label>
                  <CommentField
                    name={fieldName}
                    onChange={(e) => handleChange(e, id)}
                    value={getBlockValue({
                      id,
                      variableName: fieldName,
                    })}
                  />
                </div>
              );
            } else if (fieldType === "text") {
              return (
                <div className='block-field' key={id}>
                  <label className='formLabel'>{fieldName}</label>
                  <TextField
                    name={fieldName}
                    onChange={(e) => handleChange(e, id)}
                    value={getBlockValue({
                      id,
                      variableName: fieldName,
                    })}
                  />
                </div>
              );
            } else if (fieldType === "link") {
              return (
                <Link
                  label={fieldName}
                  value={getBlockValue({
                    id,
                    variableName: fieldName,
                  })}
                  onChange={(e) => handleChange(e, id)}
                />
              );
            } else if (fieldType === "rich_text_editor") {
              return (
                <div className='block-field' key={id}>
                  <label className='formLabel'>{fieldName}</label>
                  <RichTextEditor
                    name={fieldName}
                    onChange={(e) => handleChange(e, id)}
                    value={getBlockValue({
                      id,
                      variableName: fieldName,
                    })}
                  />
                </div>
              );
            } else {
              return null;
            }
          })}
          <Button
            title='Insert Field'
            className='add-ind-list-field-btn'
            onClick={handleAdd}
          />
        </>
      ) : (
        <Button
          title='Insert Field'
          className='add-ind-list-field-btn'
          onClick={handleAdd}
        />
      )}
    </div>
  );
}
