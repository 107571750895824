import { useState } from "react";
import { CustomDropdown } from "../../../../Components/Dropdown/customDropdown";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { IDTooltip } from "../../../../Components/Tooltip/Idtooltip";

export function Sheet({ obj, handleEdit, handleDelete }) {
  const [showOption, setShowOption] = useState(false);

  const handleMoreInfo = (e) => {
    e.stopPropagation();
    setShowOption(true);
  };
  const menu = [
    {
      title: "View",
      callBack: (e, id) => handleEdit(id),
    },
    {
      title: "Delete",
      callBack: (e, id) => handleDelete(id),
    },
  ];
  return (
    <div key={obj.id} className="integration-ind-selected-sheets-container">
      <section className="integration-ind-selected-sheets-detail">
        <p>{obj.name} </p>
        <IDTooltip value={obj._id} title="Sheet ID" />
      </section>
      {/* <p></p> */}
      <button onClick={handleMoreInfo}>
        <MoreVertOutlinedIcon />
      </button>
      {showOption ? (
        <CustomDropdown
          className="custom-dropdown-container-integration"
          showOption={showOption}
          setShowOption={setShowOption}
          menu={menu}
          id={obj.id}
        />
      ) : (
        ""
      )}
    </div>
  );
}
