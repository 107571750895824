import { Button } from "../../../Components/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomDropdown } from "../../../Components/Dropdown/customDropdown";
import { TextField } from "../../../Components/TextField/index.js";
import { websiteState } from "../../../Redux/websiteSlice";
import { DeleteOutlined } from "@mui/icons-material";
import { formState } from "../../../Redux/formSlice";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { NotifyUser } from "../../../Components/Notification";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ToolTip } from "../../../Components/Tooltip";
import { Dropdown } from "../../../Components/Dropdown";
import {
  deleteFormFieldFn,
  deleteFormFn,
  getFormFn,
  updateFormContentFn,
  updateFormFn,
} from "../../../Redux/formSlice";
import {
  deleteFormResponse,
  generateCSV,
  getFormTags,
} from "../../../Api/form";
import { updateBlock } from "../../../Api/block";

export function IndForm({ obj, setFormObj, setShowResponse, setShowCode }) {
  const {
    formName,
    id: formId,
    formData,
    no_of_responses,
    form_status,
    formType,
    emailNotification,
    email_send_count,
  } = obj;
  const { forms, loading } = useSelector(formState);

  const dispatch = useDispatch();
  const [selectedForm, setSelectedForm] = useState("");
  const [showOption, setShowOption] = useState(false);
  const { pageId, siteId: websiteId } = useSelector(websiteState);
  const [error, setError] = useState({ index: "", msg: "" });
  const [tags, setTags] = useState([]);

  const handleAdd = ({ formId }) => {
    dispatch(updateFormFn({ formId }));
  };

  useEffect(() => {
    const fetchTagNames = async () => {
      const response = await getFormTags(formType);
      if (response.error) {
        return [];
      }

      const modifiedResponse = response.map((item) => ({
        label: item,
        value: item,
      }));
      setTags(modifiedResponse);
    };
    fetchTagNames();
  }, [formId]);

  const handleChange = (e, formId, ind) => {
    try {
      let { name, value } = e.target;

      let tempForm = JSON.parse(JSON.stringify([...forms]));
      let index = tempForm.findIndex((obj) => obj.id === formId);
      if (index === -1) {
        return;
      }
      let formObj = { ...tempForm[index] };

      formObj.formData[ind][name] = value;
      setError({ index: "", msg: "" });
      dispatch(updateFormContentFn(formObj));
    } catch (error) {
      console.log(error);
    }
  };

  const handleFieldDelete = (obj) => {
    dispatch(deleteFormFieldFn(obj));
  };

  const handleFormDelete = (formId) => {
    dispatch(deleteFormFn({ formId, pageId, websiteId, formType }));
  };
  function findDuplicate(arr) {
    var duplicates = [];
    var seen = {};

    for (var prop in arr) {
      var value = arr[prop]["cmsname"];

      if (seen[value]) {
        duplicates.push({
          index: +prop,
          msg: "Name should not be repeated",
        });
      } else {
        seen[value] = true;
      }
    }

    return duplicates;
  }

  const handleSave = async ({ formId, formName, formData }) => {
    let emptyFields = [];
    for (let i in formData) {
      if (
        (!formData[i].cmsname || !formData[i].hubname) &&
        formType !== "nexaflow"
      ) {
        emptyFields.push({ index: +i, msg: "Required Field" });
      }

      if (!formData[i].cmsname && formType === "nexaflow") {
        emptyFields.push({ index: +i, msg: "Required Field" });
      }
    }

    if (emptyFields.length) {
      setError(emptyFields);

      return;
    }

    const duplicates = findDuplicate(formData);

    if (duplicates.length) {
      setError(duplicates);
      return;
    }
    setSelectedForm(formId);

    const response = await updateBlock({
      data: { formId, formName, formData, formType },
      pageId,
      websiteId,
      blockId: formId,
      type: "forms",
    });
    console.log(response.forms, response);
    if (response.error) {
      NotifyUser({ type: "error", message: response.message });
      return;
    }
    NotifyUser({ type: "success", message: "Form saved" });

    dispatch(getFormFn({ pageId, websiteId }));
  };

  const getError = (index) => {
    if (!error || !Array.isArray(error)) {
      return "";
    }
    const ind = error?.findIndex((obj) => obj.index === index);

    return error[ind]?.msg;
  };

  const handleResponse = async (obj) => {
    setFormObj(obj);
    setShowResponse(true);
  };

  const handleFormResponseDelete = async (formId) => {
    const response = await deleteFormResponse({ formId, formType });
    if (!response.error) {
      NotifyUser({ type: "success", message: "Response Deleted" });
      dispatch(getFormFn({ pageId, websiteId }));
    }
  };

  const handleCode = (obj) => {
    setShowCode(true);
    setFormObj(obj);
  };

  const handleGenerateCSV = async (formId) => {
    const response = await generateCSV(formId);
    if (response.error) {
      NotifyUser({ type: "error", message: response.message });
    }
    try {
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      NotifyUser({ type: "error", message: "Error Occurred" });
    }
  };

  const menu = [
    {
      title: "View Code",
      callBack: () => handleCode({ formId, formName, formData }),
      checkVal: "form_status",
    },
    {
      title: "View Response",
      callBack: (e) => handleResponse({ formId, formName, formData }),
      checkVal: "no_of_responses",
    },
    {
      title: "Genereate CSV",
      callBack: (e, id) => handleGenerateCSV(formId),
      checkVal: "no_of_responses",
    },
    {
      title: "Delete Form",
      callBack: () => handleFormDelete(formId),
    },
    {
      title: "Delete All Response",
      callBack: (e) => handleFormResponseDelete(formId),
      checkVal: "no_of_responses",
    },
  ];

  const menuOptions = () => {
    const filterOptions = menu.filter((obj) => {
      if (form_status === "completed" && obj.checkVal === "form_status") {
        return obj;
      } else if (no_of_responses && obj.checkVal === "no_of_responses") {
        return obj;
      } else if (!obj.checkVal) {
        return obj;
      }
    });
    return filterOptions;
  };

  const tooltipContent = (
    <div>
      <p>ID : {formId}</p>
      <p>
        Type : <span style={{ textTransform: "capitalize" }}>{formType}</span>
      </p>
      {emailNotification ? <p> Email sent - {email_send_count}</p> : null}
    </div>
  );

  return (
    <div key={formId} className='form-field-container'>
      <section className='form-field-label-container'>
        <section className='form-field-label'>
          <label className='formLabel form-label'>{formName}</label>
          <ToolTip title={tooltipContent} children={<InfoOutlinedIcon />} />

          <div className='form-moreInfo-container'>
            <button onClick={() => setShowOption(true)}>
              <MoreVertOutlinedIcon />
            </button>
            {showOption ? (
              <CustomDropdown
                className='custom-dropdown-container-form-page'
                showOption={showOption}
                setShowOption={setShowOption}
                menu={menuOptions()}
              />
            ) : (
              ""
            )}
          </div>
        </section>

        {/* <button onClick={() => handleFormDelete(formId)}>
              <DeleteOutlined />
            </button> */}
      </section>

      {formData.map((item, index) => {
        const err = getError(index);
        return (
          <div key={index} className='form-ind-field-container'>
            <div className='form-ind-field'>
              {formType !== "nexaflow" ? (
                <Dropdown
                  value={item.hubname}
                  onChange={(e) => handleChange(e, formId, index)}
                  options={tags}
                  name='hubname'
                  className='form-category'
                />
              ) : (
                ""
              )}

              <TextField
                type='text'
                name='cmsname'
                key={index}
                onChange={(e) => handleChange(e, formId, index)}
                value={item.cmsname}
              />
              <button onClick={() => handleFieldDelete({ index, formId })}>
                <DeleteOutlined />
              </button>
            </div>

            {err ? <span className='form-err-msg'>{err}</span> : ""}
          </div>
        );
      })}

      <Button
        type='button'
        className='form-addField-btn'
        title='Add Field'
        onClick={() => handleAdd({ formId })}
      />
      <Button
        type='button'
        loading={selectedForm === formId ? loading : false}
        title='Save Form'
        className='form-save-btn'
        onClick={() => handleSave({ formId, formName, formData })}
      />
    </div>
  );
}
