import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Button } from "../../../../Components/Button";
import { Popup } from "../../../../Components/Popup";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Loader } from "../../../../Components/Loader";
import { getAllWaitlist } from "../../../../Api/Integration/waitlist";
import { Account } from "./Account";
import { AddAccount } from "./AddAccount";

export function Waitlist() {
  const navigate = useNavigate();
  const [popupType, setPopupType] = useState("create");
  const [showPopup, setShowPopup] = useState(false);
  const [Accounts, setAccounts] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");

  const fetchSiteData = async () => {
    setLoading(true);
    const response = await getAllWaitlist();
    setAccounts(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSiteData();
  }, []);

  const handleEdit = (e, id) => {
    e.stopPropagation();
    const filterAccount = Accounts.filter((obj) => obj.id === id);
    if (!filterAccount[0]) {
      return;
    }
    setPopupType("update");
    setSelectedAccount(filterAccount[0]);
    setShowPopup(true);
  };

  return (
    <main className="ind-integration-page">
      {showPopup ? (
        <Popup
          children={
            <AddAccount
              setShowPopup={setShowPopup}
              fetchSiteData={fetchSiteData}
              selectedAccount={selectedAccount}
              popupType={popupType}
            />
          }
          popup={showPopup}
          setPopup={setShowPopup}
          title="Website"
          className="waitlist-integration-form-popup"
        />
      ) : (
        ""
      )}

      <section className="site-heading-container">
        <h1>
          {" "}
          <Button
            title={<KeyboardBackspaceOutlinedIcon />}
            className="navigate-btn"
            onClick={() => navigate(-1)}
          />
          Waitlist
        </h1>

        <Button
          title="+ Add Account"
          onClick={() => {
            setPopupType("create");
            setShowPopup(true);
          }}
        />
      </section>
      <section className="site-list-container">
        {Accounts && Accounts.length ? (
          Accounts.map((obj, i) => {
            return (
              <Account
                obj={obj}
                key={i}
                handleEdit={handleEdit}
                fetchSiteData={fetchSiteData}
              />
            );
          })
        ) : Accounts && !loading ? (
          <section className="message-container">
            <p>No Accounts</p>
          </section>
        ) : (
          <section className="message-container">
            <Loader />
          </section>
        )}
      </section>
    </main>
  );
}
