import { Button } from "../../../Components/Button";
import { useState } from "react";
import { TextField } from "../../../Components/TextField/index.js";
import { DeleteOutlined } from "@mui/icons-material";
import { CloudinaryUpload } from "../../Integration/IntegrationPage/Cloudinary/CloudinaryUpload";
import { Popup } from "../../../Components/Popup";
import { ImageLibrary } from "./ImageLibrary";

export function Link({ label, value, onChange, handleDelete }) {
  const [showPopup, setShowPopup] = useState(false);
  const [showLibrary, setShowLibrary] = useState(false);

  return (
    <div>
      <div className='block-field'>
        <section className='formLabel delete-btn-container'>
          {label}{" "}
          {handleDelete ? (
            <button onClick={handleDelete}>
              <DeleteOutlined />
            </button>
          ) : (
            ""
          )}
        </section>
        <section className='link-block-container'>
          <TextField name={label} onChange={onChange} value={value} />
          <Button
            type='button'
            title='upload'
            onClick={() => setShowPopup(true)}
            className='link-upload-btn'
          />
          <Button
            type='button'
            title='Library'
            onClick={() => setShowLibrary(true)}
            className='link-upload-btn'
          />
        </section>
      </div>
      {showPopup ? (
        <Popup
          children={
            <CloudinaryUpload
              fetchAccount={(val) =>
                onChange({ target: { name: label, value: val } })
              }
              setShowPopup={setShowPopup}
            />
          }
          popup={showPopup}
          setPopup={setShowPopup}
          title='File Upload'
          className='cloudinary-file-upload-popup'
        />
      ) : (
        ""
      )}
      {showLibrary ? (
        <Popup
          children={
            <ImageLibrary
              setShowLibrary={setShowLibrary}
              saveLink={(val) =>
                onChange({ target: { name: label, value: val } })
              }
            />
          }
          popup={showLibrary}
          setPopup={setShowLibrary}
          title='Image Library'
          className='cloudinary-file-library-popup'
        />
      ) : (
        ""
      )}
    </div>
  );
}
