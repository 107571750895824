import { useState, useEffect } from "react";
import { Button } from "../../../../Components/Button";
import { NotifyUser } from "../../../../Components/Notification";
import { useSearchParams } from "react-router-dom";
import { TextField } from "../../../../Components/TextField";
import { corsApi } from "../../../../Api/Integration/cors";
import { DeleteOutlineSharp } from "@mui/icons-material";
import { urlValidation } from "../../../../utils/helperFn";

export function CreateCorsApi({
  fetchCorsDetails,
  setShowPopup,
  popupType,
  selectedCorsDetail,
}) {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get("account");
  const [corsDetails, setCorsDetails] = useState({
    name: "",
    url: "",
    headers: [{ key: "", value: "" }],
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (popupType === "edit") {
      let temp = { ...selectedCorsDetail };
      temp.headers = handleHeadersFormat({
        data: temp.headers,
        type: "array",
      });

      setCorsDetails({
        ...temp,
      });
    }

    // eslint-disable-next-line
  }, [popupType, selectedCorsDetail, setCorsDetails]);

  const headerValidation = (arr) => {
    let length = arr.length;
    if (length === 1) {
      if (!arr[0].key && !arr[0].value) {
        return true;
      } else if (
        (arr[0].key && !arr[0].value) ||
        (!arr[0].key && arr[0].value)
      ) {
        return false;
      }
    } else if (length > 1) {
      for (let i in arr) {
        if ((arr[i].key && !arr[i].value) || (!arr[i].key && arr[i].value)) {
          return false;
        }
      }
    }
    return true;
  };

  const formList = [
    {
      label: "Name",
      variableName: "name",
      type: "text",
      required: true,
      message: "",
    },
    {
      label: "URL",
      variableName: "url",
      type: "text",
      required: true,
      validation: (url) => urlValidation(url),
      message: "Invalid Url",
    },

    {
      label: "Headers",
      variableName: "headers",
      type: "obj",
      validation: (obj) => headerValidation(obj),
      message: "Both keys & values are required",
      required: false,
    },
  ];

  const handleHeadersFormat = ({ data, type }) => {
    try {
      if (Array.isArray(data) && type === "obj") {
        const convertedObject = data.reduce((obj, { key, value }) => {
          if (key) {
            obj[key] = value;
          }
          return obj;
        }, {});

        return convertedObject;
      } else if (!Array.isArray(data) && type === "array") {
        let arr = [];
        if (data && Object.keys(data).length) {
          for (let i in data) {
            arr.push({ key: i, value: data[i] });
          }
        }

        return arr && arr.length ? arr : [{ key: "", value: "" }];
      } else {
        return data;
      }
    } catch (error) {
      return;
    }
  };

  function findDuplicateKeys(arr) {
    const keyCounts = {};
    const duplicateKeys = [];

    for (const obj of arr) {
      const { key } = obj;

      if (keyCounts[key]) {
        if (!duplicateKeys.includes(key)) {
          duplicateKeys.push(key);
        }
      } else {
        keyCounts[key] = 1;
      }
    }

    return duplicateKeys;
  }

  const formValidation = () => {
    const errObj = {};

    for (let i in formList) {
      if (formList[i].required && !corsDetails[formList[i]?.variableName]) {
        errObj[formList[i]?.variableName] = `${formList[i].label} is required`;
      } else if (
        formList[i]?.validation &&
        !formList[i]?.validation(corsDetails[formList[i]?.variableName])
      ) {
        errObj[formList[i]?.variableName] = formList[i]?.message;
      }
    }

    setMessage(errObj);
    return errObj;
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setCorsDetails((obj) => ({ ...obj, [name]: value }));
    let temp = { ...message };
    delete temp[name];
    setMessage(temp);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const errObj = formValidation();
    if (errObj && Object.keys(errObj).length) {
      return;
    }

    let tempObj = { ...corsDetails };

    const duplicateHeaders = findDuplicateKeys(tempObj.headers);

    if (duplicateHeaders.length) {
      setMessage({ headers: "Keys should be unique" });
      return;
    }

    let headersformatChange = handleHeadersFormat({
      data: tempObj.headers,
      type: "obj",
    });

    if (!headersformatChange) {
      return;
    }

    tempObj.headers = headersformatChange;

    setLoading(true);

    const response = await corsApi({
      method: popupType === "create" ? "POST" : "PUT",
      data: tempObj,
      id: selectedCorsDetail?.id ? selectedCorsDetail?.id : undefined,
    });

    if (response.error) {
      NotifyUser({ type: "error", message: response.message });
      setLoading(false);
      return;
    }
    setLoading(false);

    NotifyUser({
      type: "success",
      message: `"${corsDetails.name}" api created`,
    });

    await fetchCorsDetails();
    setShowPopup(false);
  };

  return (
    <div className="cors-integration-container">
      <form className="googlesheet-form-container">
        {formList.map(({ label, variableName, type }, i) => {
          if (type === "text") {
            return (
              <div key={i} className="googlesheet-ind-form-field">
                <label className="formLabel">{label}</label>
                <TextField
                  // className="google-sheet-form-field"
                  type="text"
                  value={corsDetails[variableName]}
                  name={variableName}
                  onChange={handleChange}
                />
                <span className="form-err-msg">{message[variableName]}</span>
              </div>
            );
          } else if (type === "obj") {
            return (
              <div key={i} className="googlesheet-ind-form-field">
                <label className="formLabel">{label}</label>
                <Headers
                  value={corsDetails[variableName]}
                  name={variableName}
                  setCorsDetails={setCorsDetails}
                  message={message[variableName]}
                />
              </div>
            );
          }
        })}
      </form>
      <Button
        title={popupType === "create" ? popupType : "Save"}
        className="sheet-create-btn"
        loading={loading}
        onClick={handleSave}
      />
    </div>
  );
}

export function Headers({ value, setCorsDetails, name, message }) {
  const [header, setHeader] = useState([]);

  useEffect(() => {
    setHeader(value);
  }, [value]);

  const addField = (e) => {
    e.preventDefault();
    let tempArr = [...header, { key: "", value: "" }];
    setHeader(tempArr);
    setCorsDetails((obj) => ({ ...obj, [name]: tempArr }));
  };

  const handleChange = (e, i) => {
    let { name: keyName, value } = e.target;
    let temp = [...header];
    temp[i] = { ...temp[i], [keyName]: value };
    setHeader(temp);
    setCorsDetails((obj) => ({ ...obj, [name]: temp }));
  };

  const handleDelete = (e, i) => {
    e.preventDefault();
    const filterArr = header.filter((obj, index) => index !== i);
    setHeader(filterArr);
    setCorsDetails((obj) => ({ ...obj, [name]: filterArr }));
  };

  return (
    <div className="cors-integration-headers-list">
      {header && header.length
        ? header.map(({ key, value }, i) => {
            return (
              <div className="cors-integration-ind-headers-list" key={i}>
                <TextField
                  type="text"
                  value={key}
                  name="key"
                  onChange={(e) => handleChange(e, i)}
                />
                <TextField
                  type="text"
                  value={value}
                  name="value"
                  onChange={(e) => handleChange(e, i)}
                />
                <button
                  className="cors-header-delete-btn"
                  onClick={(e) => i > 0 && handleDelete(e, i)}
                >
                  {i > 0 ? <DeleteOutlineSharp /> : ""}
                </button>
              </div>
            );
          })
        : ""}
      <span className="form-err-msg">{message}</span>
      <Button
        title="Add Headers"
        className="cors-add-headers-btn"
        onClick={addField}
      />
    </div>
  );
}
