import { Tooltip } from "antd";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Copy } from "../Copy";
import "./idtooltip.css";

export function IDTooltip({ value, title }) {
  const children = (
    <div className="id-tooltip-container">
      <p>{title}</p>
      <section className="id-tooltip-copy-container">
        <p>{value}</p>
        <Copy content={value} />
      </section>
    </div>
  );

  return (
    <Tooltip title={children} placement="right" arrow={false}>
      <InfoOutlinedIcon />
    </Tooltip>
  );
}
