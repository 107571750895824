import "./settings.css";
import { Button } from "../../Components/Button";
import { useEffect, useState } from "react";
import { apiKeyFn, getApiList } from "../../Api/settings";
import { HiddenField } from "../../Components/TextField";
import { Loader } from "../../Components/Loader";
import { Copy } from "../../Components/Copy";
import { Pricing } from "./Pricing";
import { Domain } from "./Domain";

export default function Settings() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [apiKey, setApiKey] = useState("");

  const generate = async (method) => {
    setError(false);
    setLoading(method === "GET" ? false : true);
    const response = await apiKeyFn(method);
    if (response.error) {
      setError("Error Occurred");
      return;
    }
    setApiKey(response);
    setLoading(false);
  };

  useEffect(() => {
    generate("GET");
  }, []);

  return (
    <main>
      <div className="settings-page">
        <section>
          <h1>Access Token</h1>
        </section>

        <section className="token-container">
          <p className="token-instruction">
            To access our API, generate a unique key by clicking the "Generate"
            button.If you ever suspect that your key has been compromised, you
            can generate a new key, which will automatically revoke the old one.
          </p>

          <section className="token-content">
            {apiKey ? (
              <div className="token-display-container">
                <HiddenField value={apiKey} readOnly={true} />
                <Copy content={apiKey} iconId="list-copy-icon" />
              </div>
            ) : (
              ""
            )}

            <Button
              title="Generate"
              loading={loading}
              onClick={() => generate("POST")}
            />
          </section>
        </section>
        {/* <Domain /> */}
        {/* <ApiList /> */}
        <br />
        <Pricing />
      </div>
    </main>
  );
}

function ApiList() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [apiList, setApiList] = useState("");

  useEffect(() => {
    setLoading(true);
    const getList = async () => {
      const response = await getApiList();
      if (response.error) {
        setError("Error Occurred, Try again later");
      }
      setLoading(false);
      setApiList(response);
    };
    getList();
  }, []);

  return (
    <section className="api-list-container">
      <section>
        <h1>End points</h1>
      </section>
      {apiList && Object.keys(apiList).length ? (
        <section className="api-content-container">
          {Object.entries(apiList).map(([key, value], i) => {
            return (
              <div className="ind-api-content-container" key={i}>
                <label>{key}</label>
                <p>
                  {value} <Copy content={value} iconId="list-copy-icon" />
                </p>
              </div>
            );
          })}
        </section>
      ) : apiList && !loading ? (
        <section className="api-message-container">No Endpoints found</section>
      ) : (
        <section className="api-message-container">
          <Loader />
        </section>
      )}
    </section>
  );
}
