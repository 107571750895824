import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";

export function Copy({ content, iconId, btnClassName }) {
  const [copy, setCopy] = useState(false);
  const copyBtn = copy ? (
    <LibraryAddCheckOutlinedIcon id={`${iconId}`} />
  ) : (
    <ContentCopyOutlinedIcon id={`${iconId}`} />
  );

  const handleCopy = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <CopyToClipboard text={content} onCopy={handleCopy}>
      <button className={`${btnClassName}`}>{copyBtn}</button>
    </CopyToClipboard>
  );
}
