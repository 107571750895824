import { Checkbox } from "antd";

export function CheckBox({ title, onChange, value, checked, className, name }) {
  return (
    <Checkbox
      onChange={onChange}
      value={value}
      checked={checked}
      defaultValue={value}
      className={className}
      name={name}
    >
      {title}
    </Checkbox>
  );
}
