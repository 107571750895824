import axios from "axios";
import { getItem } from "../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const createWebsite = async (data) => {
  try {
    await axios({
      url: `${base_url}/website`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const updateWebsite = async (data, websiteId) => {
  try {
    await axios({
      url: `${base_url}/website/${websiteId}`,
      method: "PUT",
      data,
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteWebsite = async (id) => {
  try {
    await axios({
      url: `${base_url}/website/${id}`,
      method: "DELETE",
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAllWebsites = async () => {
  try {
    const response = await axios({
      url: `${base_url}/website`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const downloadWebsite = async (websiteId) => {
  try {
    const response = await axios({
      url: `${base_url}/website/code/${websiteId}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const exportWebsite = async (websiteId) => {
  try {
    const link = document.createElement("a");
    link.href = `${base_url}/website/${websiteId}/export?token=${getItem(
      "token"
    )}`;
    link.download = "file.zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const importWebsite = async (data) => {
  try {
    const response = await axios({
      url: `${base_url}/website/import`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: getItem("token"),
      },
    });
    return response;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export {
  createWebsite,
  getAllWebsites,
  updateWebsite,
  deleteWebsite,
  downloadWebsite,
  importWebsite,
  exportWebsite,
};
