import { Select } from "antd";
import "./dropdown.css";

export function Dropdown({
  name,
  value,
  readOnly,
  options,
  onChange,
  onBlur,
  loading,
  className,
  placeholder,
  others,
}) {
  const handleChange = (value) => onChange({ target: { name, value } });

  return (
    <Select
      defaultValue={value}
      disabled={readOnly}
      className={`select-dropdown ${className}`}
      style={{
        width: "100%",
        height: "40px",
      }}
      value={value}
      onBlur={onBlur}
      onChange={handleChange}
      options={options}
      loading={loading}
      placeholder={placeholder}
      {...others}
    />
  );
}
