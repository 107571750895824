import axios from "axios";
import { getItem } from "../../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const corsApi = async ({ method, data, id }) => {
  try {
    const url = `${base_url}/integration/cors/${id ? id : ""}`;

    const response = await axios({
      url,
      method,
      data,
      headers: { Authorization: getItem("token") },
    });

    return { error: false, result: response?.data?.data };
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export { corsApi };
