import { useState } from "react";
import { Button } from "../../../../Components/Button";
import { uploadImageCloudinaryAccount } from "../../../../Api/Integration/cloudinary";
import { NotifyUser } from "../../../../Components/Notification";
import { dataUrlConverter } from "../../../../utils/helperFn";
import { Upload } from "../../../../Components/Upload";
import { DeleteOutlined } from "@mui/icons-material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

export function CloudinaryUpload({ fetchAccount, setShowPopup }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;

    let files = [...uploadedFiles];
    files.push({ name, dataUrl: value });
    setUploadedFiles([...files]);
  };

  const handleUpload = async (e) => {
    let file = e.target.files[0];
    let size = file.size;
    let name = file.name;
    if (size > 3000000) {
      NotifyUser({ type: "error", message: "File should be less than 3 mb" });
      return;
    }

    let value = await dataUrlConverter(file);

    handleChange({
      target: {
        name,
        value,
      },
    });
  };

  const handleDelete = (i) => {
    const filterData = uploadedFiles.filter((data, index) => index !== i);
    setUploadedFiles([...filterData]);
  };

  const saveImage = async () => {
    if (!uploadedFiles.length) {
      return;
    }
    setLoading(true);
    // fetchAccount("link");
    const response = await uploadImageCloudinaryAccount({
      data: uploadedFiles[0],
    });
    if (!response.error) {
      NotifyUser({ type: "success", message: "File Uploaded" });
      fetchAccount(response);
    } else {
      setLoading(false);
      NotifyUser({ type: "error", message: response.message });
    }
    setLoading(false);
    setShowPopup(false);
  };

  const Children = (
    <section className="upload-file-design">
      <CloudUploadOutlinedIcon id="cloud-upload-icon" />
      <p>Upload</p>
      <p>File limit upto 3mb</p>
    </section>
  );

  return (
    <div className="cloudinary-file-upload-container">
      <Upload onChange={handleUpload} accept="image/*" Children={Children} />

      {uploadedFiles && uploadedFiles.length ? (
        <div className="cloudinary-upload-file-list">
          {uploadedFiles.map(({ name, value }, i) => {
            return (
              <div className="cloudinary-upload-ind-file-list" key={i}>
                <section>
                  {/* <img src={value} alt={name} title={name} /> */}
                  <p>{name}</p>
                </section>
                <button onClick={() => handleDelete(i)}>
                  <DeleteOutlined />
                </button>
              </div>
            );
          })}

          <Button
            title="Upload"
            className="cloudinary-upload-btn"
            onClick={saveImage}
            loading={loading}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
