import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState, useRef } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HtmlOutlinedIcon from "@mui/icons-material/HtmlOutlined";
import TextFieldsOutlinedIcon from "@mui/icons-material/TextFieldsOutlined";

export function RichTextEditor({ onChange, value, name }) {
  const [text, setText] = useState(EditorState.createEmpty());
  const [contentType, setContentType] = useState("rawContent");
  const [storedValue, setStoredValue] = useState("");
  const textareaRef = useRef(null);

  const setEditorValue = (value) => {
    const contentBlock = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    setText(editorState);
  };

  useEffect(() => {
    if (!storedValue && value) {
      setStoredValue(value);
      setEditorValue(value);
    }
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
    // eslint-disable-next-line
  }, [value]);

  const onInput = (data) => {
    setText(data);
    const rawContentState = draftToHtml(convertToRaw(data.getCurrentContent()));
    let e = { target: { name, value: rawContentState } };

    onChange(e);
  };

  const CustomOption = () => {
    const handleClick = () => {
      const plainEditor = document.querySelector(".richText-editor");
      const htmlEditor = document.querySelector(".htmlText-editor");

      const type = contentType === "rawContent" ? "html" : "rawContent";
      setContentType(type);

      if (type === "html") {
        htmlEditor.style.display = "block";
        plainEditor.style.display = "none";
      } else {
        htmlEditor.style.display = "none";
        plainEditor.style.display = "block";
      }
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

    return (
      <button onClick={handleClick} className="rdw-option-wrapper">
        {contentType !== "html" ? (
          <HtmlOutlinedIcon />
        ) : (
          <TextFieldsOutlinedIcon />
        )}
      </button>
    );
  };

  return (
    <>
      <Editor
        editorState={text}
        wrapperClassName="demo-wrapper"
        editorClassName="richText-editor"
        onEditorStateChange={(data) => onInput(data)}
        toolbarCustomButtons={[<CustomOption />]}
      />
      <textarea
        className="htmlText-editor"
        name={name}
        onChange={onChange}
        onInput={(e) => setEditorValue(e.target.value)}
        value={value}
        ref={textareaRef}
      />
    </>
  );
}
