import { useSelector } from "react-redux";
import { blockState } from "../../../Redux/blockSlice";
import { List } from "./List";

export function NestedList({
  nestedListId,
  handleLibrary,
  handleDelete,
  setContentType,
  nestedObj,
}) {
  const { blocks: blockArr } = useSelector(blockState);

  const blockIndex = blockArr.findIndex(({ id }) => id === nestedListId);

  if (blockIndex < 0) {
    return null;
  }

  return (
    <>
      <List
        setContentType={setContentType}
        block={blockArr[blockIndex]}
        handleLibrary={handleLibrary}
        nestedObj={nestedObj}
      />
    </>
  );
}
