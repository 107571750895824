import { useEffect, useState } from "react";
import "./analytics.css";
import { dateFormatter } from "../../utils/helperFn";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { getAnalytics, getAnalyticsFilter } from "../../Api/analytics";
import { formatQueryDate, modifyArr } from "./helper";
import { NotifyUser } from "../../Components/Notification";
import { Loader } from "../../Components/Loader";
import { Dropdown } from "../../Components/Dropdown";

export function Analytics() {
  const { RangePicker } = DatePicker;
  const currentDate = dayjs(
    dateFormatter({ date: new Date(), format: "YYYY/MM/DD" }),
    "YYYY/MM/DD"
  );

  const [successData, setSuccessData] = useState("");
  const [failureData, setFailureData] = useState("");
  const [selectedRange, setSelectedRange] = useState([
    currentDate,
    currentDate,
  ]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [filter, setFilter] = useState({
    tag: "Page",
    client: "Server",
    fromDate: "",
    toDate: "",
  });

  const fetchAnalytics = async (query) => {
    setLoading(true);
    const response = await getAnalytics(query);

    if (!response) {
      setLoading(false);
      return;
    }

    if (response.error) {
      NotifyUser({
        type: "error",
        message: response.message,
      });
      setLoading(false);

      return;
    }

    if (response && !response.length) {
      setSuccessData([]);
      setFailureData([]);
      setLoading(false);

      return;
    }

    const successResults = response.filter((obj) => obj.status === "success");
    const failureResults = response.filter((obj) => obj.status === "failed");

    const formatedSuccessResults = modifyArr(successResults);
    const formatedFailureResults = modifyArr(failureResults);

    setSuccessData(
      Array.isArray(formatedSuccessResults) ? formatedSuccessResults : []
    );
    setFailureData(
      Array.isArray(formatedFailureResults) ? formatedFailureResults : []
    );
    setLoading(false);
  };

  const fetchFilters = async () => {
    const response = await getAnalyticsFilter();

    if (response.error) {
      NotifyUser({
        type: "error",
        message: response.message,
      });
      return;
    }

    setFilterData(response);
  };

  useEffect(() => {
    fetchFilters();
    const currentDate = new Date().toISOString();
    let format = formatQueryDate(currentDate);
    let queryParam = `?fromDate=${format}&toDate=${format}`;
    setFilter({ ...filter, fromDate: format, toDate: format });
    fetchAnalytics(queryParam);
  }, []);

  const queryText = (tempFilter) => {
    let query = "";

    for (let i in tempFilter) {
      if (tempFilter[i] && !query) {
        query = `?${i}=${tempFilter[i]}`;
      } else if (tempFilter[i] && query) {
        query += `&${i}=${tempFilter[i]}`;
      }
    }
    return query;
  };

  const handleRangeChange = (dates) => {
    setSelectedRange(dates);
    if (
      dates[0] &&
      dates[0].toISOString() &&
      dates[1] &&
      dates[1].toISOString()
    ) {
      return {
        fromDate: formatQueryDate(dates[0].toISOString()),
        toDate: formatQueryDate(dates[1].toISOString()),
      };
    } else {
      const currentDate = new Date().toISOString();
      return {
        fromDate: formatQueryDate(currentDate),
        toDate: formatQueryDate(currentDate),
      };
    }
  };

  const handleFilter = (e) => {
    let { name, value } = e.target;
    let tempFilter = { ...filter };
    if (name === "date") {
      let dateObj = handleRangeChange(value);
      tempFilter = dateObj && { ...tempFilter, ...dateObj };
    } else {
      tempFilter[name] = value;
    }

    setFilter(tempFilter);

    let queryParam = queryText(tempFilter);
    fetchAnalytics(queryParam);
  };

  return (
    <div className="analytics-page">
      <section>
        <h1>Analytics</h1>
      </section>
      <div className="analytics-filter-container">
        <section className="analytics-ind-filter-container">
          {/* <p>Date</p> */}
          <RangePicker
            value={selectedRange}
            onChange={(val) =>
              handleFilter({ target: { name: "date", value: val } })
            }
            format="DD-MM-YYYY"
            // defaultValue={currentDate}
            allowClear={false}
          />
        </section>
        <section className="analytics-ind-filter-container">
          {/* <p>API</p> */}
          <Dropdown
            options={filterData?.tags?.map((item) => ({
              label: item,
              value: item,
            }))}
            name="tag"
            placeholder="Select Api"
            value={filter["tag"] ? filter["tag"] : undefined}
            onChange={handleFilter}
          />
        </section>
        <section className="analytics-ind-filter-container">
          {/* <p>Source</p> */}
          <Dropdown
            options={filterData?.client?.map((item) => ({
              label: item,
              value: item,
            }))}
            name="client"
            placeholder="Select Source"
            value={filter["client"] ? filter["client"] : undefined}
            onChange={handleFilter}
          />
        </section>
      </div>
      {(successData && successData.length) ||
      (failureData && failureData.length) ? (
        <div className="analytics-chart-container">
          {successData && successData.length ? (
            <div className="chart-container">
              <MyChart
                data={successData}
                color="green"
                type="Success"
                key="success"
              />
            </div>
          ) : (
            ""
          )}

          {failureData && failureData.length ? (
            <div className="chart-container">
              <MyChart
                data={failureData}
                color="red"
                type="Failed"
                key="failed"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : loading ? (
        <div className="analytics-msg-container">
          <Loader />
        </div>
      ) : (
        <div className="analytics-msg-container">
          <p>No Insights</p>{" "}
        </div>
      )}
    </div>
  );
}

const MyChart = ({ data, color, type }) => {
  const tickFormatter = (tick) => {
    // return moment(tick).format("hh:mmA");
    return dateFormatter({ date: tick, format: "hh:mmA" });
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="analytics-graph-tooltip">
          <p>
            URL <span>{payload[0].payload?.url}</span>
          </p>
          <p>
            Method <span>{payload[0].payload?.method}</span>
          </p>
          <p>
            Status <span style={{ color }}>{type}</span>
          </p>
          <p>
            <span>
              {dateFormatter({
                date: payload[0].payload?.originalDate,
                format: "dddd DD MMMM , hh:mm:ss a",
              })}
            </span>
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <LineChart
      className="analytics-linechart"
      width={800}
      height={300}
      data={data}
      margin={{
        top: 15,
        right: 10,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />

      <XAxis dataKey="date" className="analytics-axis-label" />
      <YAxis
        dataKey="time"
        type="number"
        domain={["dataMin", "dataMax"]}
        tickFormatter={tickFormatter}
        className="analytics-axis-label"
      />
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      <Line type="monotone" dataKey="time" stroke={color} name="" />
      <Line
        type="monotone"
        stroke={color}
        dataKey="status"
        name="status"
        strokeWidth={2}
        activeDot={{ r: 8 }}
      />
    </LineChart>
    // </ResponsiveContainer>
  );
};
