import { Button } from "../../../../Components/Button";
import { HiddenField, TextField } from "../../../../Components/TextField";
import { NotifyUser } from "../../../../Components/Notification/index.js";
import { useState, useEffect } from "react";
import {
  createWaitlist,
  updateWaitlist,
} from "../../../../Api/Integration/waitlist";
import { emailValidation } from "../../../../utils/helperFn";

export function AddAccount({
  setShowPopup,
  fetchSiteData,
  selectedAccount,
  popupType,
}) {
  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const form = [
    {
      label: "Email",
      type: "text",
      variableName: "email",
    },
    {
      label: "Password",
      type: "hiddenField",
      variableName: "password",
    },
  ];

  useEffect(() => {
    if (
      selectedAccount &&
      Object.keys(selectedAccount).length &&
      popupType === "update"
    ) {
      setInput(selectedAccount);
    }
  }, [selectedAccount, popupType]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let temp = {
      ...input,
    };
    temp[name] = value;
    setInput({ ...temp });
    setError("");
  };

  const handleClick = async () => {
    if (input && (!input?.email || !input?.password)) {
      setError("All Fields Required");

      return;
    }
    for (let i in input) {
      if (!input[i]) {
        setError("All Fields Required");
        return;
      }
    }

    let validate = emailValidation(input.email);

    if (!validate) {
      setError("Invalid Email");
      return;
    }

    setError("");
    setLoading(true);

    let response;

    if (popupType === "create") {
      response = await createWaitlist(input);
    } else if (popupType === "update") {
      response = await updateWaitlist({
        ...input,
        id: selectedAccount ? selectedAccount?.id : undefined,
      });
    }

    setLoading(false);
    if (!response.error) {
      NotifyUser({
        type: "success",
        message: `Website ${popupType}d successfully`,
      });
      setShowPopup(false);
    } else {
      setError(response.message);
    }
    fetchSiteData();
  };

  return (
    <main>
      <form className='page-create-form'>
        {form.map(({ label, type, variableName }) => {
          if (type === "text") {
            return (
              <div className='ind-page-create-field' key={variableName}>
                <label className='formLabel'>{label}</label>
                <TextField
                  type={type}
                  onChange={handleChange}
                  name={variableName}
                  value={input[variableName]}
                />
              </div>
            );
          } else if (type === "hiddenField") {
            return (
              <div className='ind-page-create-field' key={variableName}>
                <label className='formLabel'>{label}</label>
                <HiddenField
                  onChange={handleChange}
                  name={variableName}
                  value={input[variableName]}
                />
              </div>
            );
          }

          return null;
        })}

        <p className='auth-error-message'>{error ? error : ""}</p>

        <Button
          title={popupType === "create" ? "Create" : "Update"}
          type='button'
          className='site-create-btn'
          onClick={handleClick}
          loading={loading}
        />
      </form>
    </main>
  );
}
