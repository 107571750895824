import { useState } from "react";
import { HiddenField } from "../../Components/TextField";
import { imgObj } from "../../utils/image";
import { Button } from "../../Components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { resetpasswordApi } from "../../Api/auth";

export function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const { id: token } = useParams();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
    setMessage("");
  };

  const handleSubmit = async () => {
    setMessage("");
    const { password, confirmPassword } = formData;

    if (!password || !confirmPassword) {
      setMessage({ type: "error", result: "All fields required" });
      return;
    }

    if (password !== confirmPassword) {
      setMessage({ type: "error", result: "Password does not match" });
      return;
    }

    const passwordValidation =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const validate = passwordValidation.test(password);

    if (!validate) {
      setMessage({
        type: "error",
        result:
          "Password should have at least 8 characters, a letter, a number, and a special character",
      });
      return;
    }

    setLoading(true);
    const result = await resetpasswordApi({ data: { password }, token });

    if (result.error) {
      setMessage({ type: "error", result: result.message });
      setLoading(false);
      return;
    }
    setLoading(false);
    navigate("/success/password");
  };

  return (
    <div className="forgotpassword-page">
      <div className="forgotpassword-container">
        <div class="login-img-container">
          <img src={imgObj.logo} class="auth-login-logo" alt="logo" />
          <img
            src={imgObj.forgotPassword2}
            class="forgot-password-illustration-img"
            alt="illustration"
          />
        </div>

        <div className="login-content-container">
          <div>
            <p className="login-heading">Reset Password</p>
            <p className="login-description">Choose new password for Login</p>
          </div>
          <br />
          <div className="login-field-container">
            <HiddenField
              name="password"
              onChange={handleChange}
              value={formData.password}
              className="register-field-password"
              placeholder="New Password"
              autoFocus={true}
            />

            <div className="field-divider" />
            <HiddenField
              name="confirmPassword"
              onChange={handleChange}
              value={formData.confirmPassword}
              className="register-field-confirm-password"
              placeholder="Confirm Password"
            />

            <p
              className={
                message?.type === "success"
                  ? "login-success-message"
                  : "login-err-message"
              }
            >
              {message?.result}
            </p>
          </div>
          <br />
          <Button
            title="Send"
            // disabled={disabled}
            className="login-btn"
            loading={loading}
            onClick={handleSubmit}
          />

          <div className="forgot-password-info-container">
            <p>Remember your password? </p>
            <Button
              onClick={() => navigate("/")}
              title="Login"
              className="forgot-password-login-btn"
            />
          </div>
        </div>
      </div>
      <img src={imgObj.wave} class="login-wave-img" alt="wave" />
    </div>
  );
}
