import axios from "axios";
import { getItem } from "../../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const getAllHubspotAccounts = async () => {
  try {
    const response = await axios({
      url: `${base_url}/integration/hubspot/user`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getHubspotAccount = async () => {
  try {
    const response = await axios({
      url: `${base_url}/integration/hubspot`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteHubspotAccount = async (id) => {
  try {
    await axios({
      url: `${base_url}/integration/hubspot/user/${id}`,
      method: "DELETE",
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export { getAllHubspotAccounts, deleteHubspotAccount, getHubspotAccount };
