import { useState, useEffect } from "react";
import { Popup } from "../../../Components/Popup";
import { CustomDropdown } from "../../../Components/Dropdown/customDropdown";
import { Button } from "../../../Components/Button";
import { Loader } from "../../../Components/Loader";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { TextField } from "../../../Components/TextField";
import { NotifyUser } from "../../../Components/Notification/index.js";
import {
  createCaptcha,
  deleteCaptcha,
  getAllCaptcha,
  updateCaptcha,
} from "../../../Api/Integration/recaptcha";
import dracula from "prism-react-renderer/themes/dracula";
import Highlight, { defaultProps } from "prism-react-renderer";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Copy } from "../../../Components/Copy";
import { DeleteOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export function Recaptcha() {
  const [sites, setSites] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPagePopup, setShowPagePopup] = useState(false);
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [selectedSite, setSelectedSite] = useState("");
  const [popupType, setPopupType] = useState("new");
  const [codeData, setCodeData] = useState("");
  const navigate = useNavigate();

  const fetchSiteData = async () => {
    setLoading(true);
    const response = await getAllCaptcha();
    setSites(response.data);
    setCodeData({ HOST_URL: response.host, client_key: "" });
    setLoading(false);
  };

  useEffect(() => {
    fetchSiteData();
  }, []);

  const handleEdit = (e, id) => {
    e.stopPropagation();
    const filterSite = sites.filter((obj) => obj.id === id);
    if (!filterSite[0]) {
      return;
    }
    setPopupType("update");
    setSelectedSite(filterSite[0]);
    setShowPagePopup(true);
  };

  const handleClick = (client_key) => {
    setShowCodePopup(true);
    let temp = { ...codeData };
    temp.client_key = client_key;
    setCodeData(temp);
  };

  return (
    <main className='ind-integration-page'>
      {showPagePopup ? (
        <Popup
          children={
            <SiteCreate
              setShowPagePopup={setShowPagePopup}
              fetchSiteData={fetchSiteData}
              selectedSite={selectedSite}
              popupType={popupType}
            />
          }
          popup={showPagePopup}
          setPopup={setShowPagePopup}
          title='Website'
          className='integration-form-popup'
        />
      ) : (
        ""
      )}

      {showCodePopup ? (
        <Popup
          children={
            <div className='code-display-container'>
              <CodeExample obj={{ ...codeData }} />
            </div>
          }
          popup={showCodePopup}
          setPopup={setShowCodePopup}
          title='Sample Code'
          className='integration-recaptcha-code-popup'
        />
      ) : (
        ""
      )}
      <section className='site-heading-container'>
        <h1>
          {" "}
          <Button
            title={<KeyboardBackspaceOutlinedIcon />}
            className='navigate-btn'
            onClick={() => navigate(-1)}
          />{" "}
          reCAPTCHA
        </h1>
        <Button
          title='+ Add site'
          onClick={() => {
            setShowPagePopup(true);
            setPopupType("create");
          }}
        />
      </section>

      <section className='site-list-container'>
        {sites && sites.length ? (
          sites.map((obj, i) => {
            return (
              <Site
                obj={obj}
                key={i}
                handleEdit={handleEdit}
                fetchSiteData={fetchSiteData}
                handleClick={handleClick}
              />
            );
          })
        ) : sites && !loading ? (
          <section className='message-container'>
            <p>No Recent Websites</p>
          </section>
        ) : (
          <section className='message-container'>
            <Loader />
          </section>
        )}
      </section>
    </main>
  );
}

function Site({ obj, fetchSiteData, handleEdit, handleClick }) {
  const { name, id, client_key } = obj;

  const [showOption, setShowOption] = useState(false);

  const handleMoreInfo = (e) => {
    // e.stopPropagation();
    setShowOption(true);
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation();
    const response = await deleteCaptcha(id);
    if (!response?.error) {
      fetchSiteData();
      NotifyUser({
        type: "success",
        message: `Website Deleted`,
      });
    }
  };

  const menu = [
    {
      title: "Edit",
      callBack: (e, id) => handleEdit(e, id),
    },
    {
      title: "Delete",
      callBack: (e, id) => handleDelete(e, id),
    },
    {
      title: "View Code",
      callBack: (e) => handleClick(client_key),
    },
  ];
  return (
    <div
      key={id}
      className='ind-integration'
      // onClick={() => handleClick(client_key)}
    >
      <section className='recaptcha-site-details'>
        <section>
          <p>{name} </p>
        </section>

        <button onClick={handleMoreInfo}>
          <MoreVertOutlinedIcon />
        </button>
        {showOption ? (
          <CustomDropdown
            className='custom-dropdown-container-integration'
            showOption={showOption}
            setShowOption={setShowOption}
            menu={menu}
            id={id}
          />
        ) : (
          ""
        )}
      </section>

      {/* <section className="site-update-details">
        <label>Last Updated</label>
        <p className="site-last-update">
          {dateFormatter({
            date: updatedAt,
            format: "DD MMM YYYY, hh:mm a",
          })}
        </p>
      </section> */}
    </div>
  );
}

export function SiteCreate({
  setShowPagePopup,
  fetchSiteData,
  selectedSite,
  popupType,
}) {
  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [result, setResult] = useState("");

  const form = [
    {
      label: "Website Name",
      type: "text",
      variableName: "name",
    },
    {
      label: "Domain Name",
      type: "group",
      variableName: "domain",
    },
    {
      label: "Client Key",
      type: "text",
      variableName: "client_key",
    },
    {
      label: "Secret Key",
      type: "text",
      variableName: "secret_key",
    },
  ];

  useEffect(() => {
    if (
      selectedSite &&
      Object.keys(selectedSite).length &&
      popupType === "update"
    ) {
      setInput(selectedSite);
    }
  }, [selectedSite, popupType]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let temp = { ...input };
    temp[name] = value;
    setInput({ ...temp });
    setError("");
  };

  const handleClick = async () => {
    if ((input && !Object.keys(input).length) || !input.domain) {
      setError("All Fields Required");

      return;
    }
    for (let i in input) {
      if (!input[i]) {
        setError("All Fields Required");
        return;
      }
      if (Array.isArray(input[i])) {
        if (!input[i].length) {
          setError("All Fields Required");
          return;
        }

        for (let j in input[i]) {
          if (!input[i][j]) {
            setError("All Fields Required");

            return;
          }
        }
      }
    }

    // return;
    setError("");

    setLoading(true);

    let response;

    if (popupType === "create") {
      response = await createCaptcha(input);
    } else if (popupType === "update") {
      response = await updateCaptcha(input);
    }

    setLoading(false);
    if (!response.error) {
      NotifyUser({
        type: "success",
        message: `Website ${popupType}d successfully`,
      });
      setResult({
        client_key: response?.data.client_key,
        HOST_URL: response?.host,
      });
      console.log(response);
    } else {
      setError(response.message);
    }
    fetchSiteData();

    // setShowPagePopup(false);
  };

  const handleDomainChange = (e) => {
    let { name, value } = e.target;
    setError("");
    const temp = { ...input };
    if (Array.isArray(temp.domain)) {
      temp.domain[name] = value;
    } else {
      temp.domain = [value];
    }
    setInput(temp);
  };

  const handleAddDomain = () => {
    let temp = { ...input };
    if (Array.isArray(temp.domain)) {
      temp.domain.push("");
    } else {
      temp.domain = [temp.domain, ""];
    }
    setInput(temp);
  };

  const handleDeleteDomain = (i) => {
    let temp = { ...input };
    const filterDomain = temp.domain.filter((data, index) => index !== i);
    temp.domain = filterDomain;
    setInput(temp);
    setError("");
  };

  return (
    <main>
      {!result ? (
        <form className='page-create-form'>
          {form.map(({ label, type, variableName }) => {
            if (type === "text") {
              return (
                <div className='ind-page-create-field' key={variableName}>
                  <label className='formLabel'>{label}</label>
                  <TextField
                    type={type}
                    onChange={handleChange}
                    name={variableName}
                    value={input[variableName]}
                  />
                </div>
              );
            } else if (type === "group") {
              const domainArr = Array.isArray(input[variableName])
                ? input[variableName]
                : [""];
              return (
                <div className='ind-captcha-create-field' key={variableName}>
                  <label className='formLabel'>{label}</label>
                  <div className='recaptcha-domain-field-container'>
                    {domainArr.map((item, i) => {
                      return (
                        <div className='recaptcha-domain-ind-field-container'>
                          <TextField
                            type={type}
                            key={i}
                            onChange={handleDomainChange}
                            name={i}
                            value={item}
                          />
                          {i !== 0 ? (
                            <button
                              type='button'
                              onClick={() => handleDeleteDomain(i)}
                            >
                              <DeleteOutlined />
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <Button
                    type='button'
                    title='Add Domain'
                    className='add-field-recaptcha-btn'
                    onClick={handleAddDomain}
                  />
                </div>
              );
            }
            return null;
          })}

          {error ? <p className='auth-error-message'>{error}</p> : ""}

          <Button
            title={popupType === "create" ? "Create" : "Update"}
            type='button'
            className='site-create-btn'
            onClick={handleClick}
            loading={loading}
          />
        </form>
      ) : (
        <div className='code-display-container'>
          <CodeExample obj={result} />
        </div>
      )}
    </main>
  );
}

const CodeExample = ({ obj }) => {
  const { client_key = "YOUR CLIENT KEY", HOST_URL = "HOST URL" } = obj;
  const code = `
    // paste this code inside your html file head tag
    <script src="https://www.google.com/recaptcha/api.js" async defer></script>

    // paste this code inside your html file body tag
    <form id='google-recaptcha'>
      <div class="g-recaptcha" data-sitekey="${client_key}"></div>
      <br/>
      <input type="submit" value="Submit">
    </form>
    <script>
      document.getElementById('google-recaptcha').addEventListener('submit', function(e){
        e.preventDefault();
        console.log('submit')
        const captcha = document.querySelector('.g-recaptcha-response').value;
        fetch('${HOST_URL}/integration/google', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': "YOUR API KEY"
          },
          body: JSON.stringify({
            captcha,
            client_key: "${client_key}",
          })
        })
          .then(response => response.json())
          .then(data => {
            console.log(data);
            /** your code here **/
          })
          .catch(error => {
            console.log(error);
            /** your code here **/
          });
      });
    </script>
  `;

  return (
    <>
      <Copy
        content={code}
        iconId='code-copy-icon'
        btnClassName='copy-code-btn'
      />

      <Highlight {...defaultProps} theme={dracula} code={code} language='html'>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </>
  );
};
