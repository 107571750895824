import "./upload.css";

export function Upload({
  name,
  value,
  onChange,
  accept,
  readOnly,
  multiple,
  Children,
}) {
  return (
    <main>
      <UploadContainer
        onChange={onChange}
        accept={accept}
        readOnly={readOnly}
        Children={Children}
        multiple={multiple}
        name={name}
        value={value}
      />
    </main>
  );
}

function UploadContainer({
  name,
  value,
  onChange,
  accept,
  readOnly,
  Children,
  multiple,
}) {
  return (
    <div className="uploadFile-container">
      <label htmlFor="upload" title={value}>
        {Children}
      </label>
      <input
        id="upload"
        accept={accept}
        disabled={readOnly}
        name={name}
        type="file"
        multiple={multiple}
        onChange={onChange}
      />
    </div>
  );
}
