import "./emailVerification.css";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Button } from "../../../../Components/Button";
import { Popup } from "../../../../Components/Popup";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Loader } from "../../../../Components/Loader";
import { getAllEmailVerify } from "../../../../Api/Integration/emailVerification";
import { Site } from "./Site";
import { SiteCreate } from "./SiteCreate";

export function EmailVerification() {
  const navigate = useNavigate();
  const [popupType, setPopupType] = useState("create");
  const [showPopup, setShowPopup] = useState(false);
  const [sites, setSites] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedSite, setSelectedSite] = useState("");

  const fetchSiteData = async () => {
    setLoading(true);
    const response = await getAllEmailVerify();
    setSites(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSiteData();
  }, []);

  const handleEdit = (e, id) => {
    try {
      e.stopPropagation();
      let filterSite = sites.filter((obj) => obj.id === id);
      if (!filterSite[0]) {
        return;
      }
      setPopupType("update");
      delete filterSite[0].email_send_count;
      setSelectedSite(filterSite[0]);
      setShowPopup(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main className="ind-integration-page">
      {showPopup ? (
        <Popup
          children={
            <SiteCreate
              setShowPopup={setShowPopup}
              fetchSiteData={fetchSiteData}
              selectedSite={selectedSite}
              popupType={popupType}
            />
          }
          popup={showPopup}
          setPopup={setShowPopup}
          title="Website"
          // className="clarity-integration-form-popup"
        />
      ) : (
        ""
      )}

      <section className="site-heading-container">
        <h1>
          {" "}
          <Button
            title={<KeyboardBackspaceOutlinedIcon />}
            className="navigate-btn"
            onClick={() => navigate(-1)}
          />
          Email Verification
        </h1>

        <Button
          title="+ Add Website"
          onClick={() => {
            setPopupType("create");
            setShowPopup(true);
          }}
        />
      </section>
      <section className="site-list-container">
        {sites && sites.length ? (
          sites.map((obj, i) => {
            return (
              <Site
                obj={obj}
                key={i}
                handleEdit={handleEdit}
                fetchSiteData={fetchSiteData}
              />
            );
          })
        ) : sites && !loading ? (
          <section className="message-container">
            <p>No Recent Websites</p>
          </section>
        ) : (
          <section className="message-container">
            <Loader />
          </section>
        )}
      </section>
    </main>
  );
}
