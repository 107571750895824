import { useState, useEffect } from "react";
import { Button } from "../../../../Components/Button";
import {
  getCloudinaryImages,
  deleteCloudinaryImages,
} from "../../../../Api/Integration/cloudinary";
import { Loader } from "../../../../Components/Loader";
import { NotifyUser } from "../../../../Components/Notification";
import { Popup } from "../../../../Components/Popup";
import { TextField } from "../../../../Components/TextField/index.js";
import { dateFormatter } from "../../../../utils/helperFn";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { Dropdown } from "../../../../Components/Dropdown";
import { CheckBox } from "../../../../Components/Checkbox";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { CloudinaryUpload } from "./CloudinaryUpload";

export function CloudinaryAccount({ accountId }) {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedImages, setSelectedImages] = useState({ names: [] });
  const [images, setImages] = useState("");
  const [filter, setFilter] = useState({ name: "", sort: "desc" });
  const [cursor, setCursor] = useState([""]);
  const [nextCursor, setNextCursor] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchImages = async (obj) => {
    setError("");
    const response = await getCloudinaryImages(obj);
    if (response.error) {
      setError(response.message);
      return;
    }

    setImages(response.resources);

    if (!cursor.includes(response.next_cursor)) {
      setCursor([...cursor, response.next_cursor]);
    }
    setNextCursor(response.next_cursor);
  };

  useEffect(() => {
    fetchImages({});

    // eslint-disable-next-line
  }, []);

  const handleLink = (e, link) => {
    e.stopPropagation();
    window.open(link, "_blank");
  };

  const sortOptions = [
    { label: "Ascending", value: "asc" },
    { label: "Descending", value: "desc" },
  ];

  const handleChange = (e) => {
    let { value, name } = e.target;
    let temp = { ...filter };
    temp[name] = value;
    setFilter({ ...temp });

    fetchImages({ query: `&name=${temp.name}&sort=${temp.sort}` });
  };

  const handleCursor = (type) => {
    const getPrev = (nextCursor) => {
      let index = cursor.findIndex((item) => item === nextCursor);
      console.log(cursor, index);
      return cursor[index - 2];
    };

    let pageCursor = type === "prev" ? getPrev(nextCursor) : nextCursor;

    fetchImages({
      query: `&name=${filter.name}&sort=${filter.sort}`,
      cursor: pageCursor ? pageCursor : "",
    });
  };

  const selectImages = (id) => {
    let temp = [...selectedImages.names];
    if (temp.includes(id)) {
      temp = temp.filter((item) => item !== id);
    } else {
      temp.push(id);
    }
    setSelectedImages({ names: [...temp] });
  };

  const handleDelete = async () => {
    setLoading(true);

    const response = await deleteCloudinaryImages(selectedImages);
    if (response.error) {
      NotifyUser({ type: "error", message: response.message });
      setLoading(false);
      return;
    }
    NotifyUser({
      type: "error",
      message: `${selectedImages?.names?.length} ${
        selectedImages?.names?.length > 1 ? "files" : "file"
      } deleted`,
    });
    setLoading(false);
    setSelectedImages({ names: [] });
    fetchImages({});
  };

  return (
    <main className="ind-integration-page">
      <section className="site-heading-container">
        <h1>Cloudinary</h1>
        <Button
          title="+ Upload File"
          onClick={() => {
            setShowPopup(true);
          }}
        />
      </section>
      {showPopup ? (
        <Popup
          children={
            <CloudinaryUpload
              fetchAccount={fetchImages}
              setShowPopup={setShowPopup}
              accountId={accountId}
            />
          }
          popup={showPopup}
          setPopup={setShowPopup}
          title="File Upload"
          className="cloudinary-file-upload-popup"
        />
      ) : (
        ""
      )}

      {/* <section className="cloudinary-link-container">
              {accountDetails && accountDetails.url.length ? (
                accountDetails.url.map(({ name, domain }, i) => {
                  return (
                    <section
                      key={i}
                      onClick={() => handleLink(domain)}
                      className="cloudinary-ind-link-container"
                    >
                      <img src={domain} alt={name} title={name} />
                      <a href={domain} rel="noreferrer" target="_blank">
                        {name}{" "}
                      </a>
                    </section>
                  );
                })
              ) : accountDetails && !loading ? (
                <section className="message-container">
                  <p>No Files Found</p>
                </section>
              ) : (
                <section className="message-container">
                  <Loader />
                </section>
              )}
            </section> */}

      <div className="image-library">
        <div className="image-library-filter-container">
          <TextField
            type="text"
            placeholder="Search"
            name="name"
            onChange={handleChange}
          />
          <Dropdown
            options={sortOptions}
            name="sort"
            value={filter.sort}
            className="image-library-filter-dropdown"
            onChange={handleChange}
          />
          <Button
            loading={loading}
            onClick={handleDelete}
            className={
              !selectedImages?.names?.length &&
              "disabled-deleteCloudinary-image-btn"
            }
            disabled={!selectedImages?.names?.length}
            title={`Delete(${selectedImages?.names?.length})`}
          />
        </div>

        <div className="cloudinary-image-content">
          {images && images.length ? (
            images.map(
              ({ filename, secure_url, uploaded_at, public_id }, i) => {
                const isSelected = selectedImages.names.includes(public_id);

                return (
                  <div
                    key={i}
                    onClick={() => selectImages(public_id)}
                    className="cloudinary-ind-image-content"
                  >
                    <section>
                      <CheckBox title="" checked={isSelected} />

                      <img
                        src={secure_url}
                        loading="lazy"
                        alt={filename}
                        title={filename}
                      />
                      <p className="cloudinary-ind-image-name">{filename}</p>
                      <p className="cloudinary-ind-image-date">
                        {dateFormatter({
                          format: "DD/MM/YYYY",
                          date: uploaded_at,
                        })}
                      </p>
                    </section>

                    <button onClick={(e) => handleLink(e, secure_url)}>
                      <OpenInNewOutlinedIcon />
                    </button>
                  </div>
                );
              }
            )
          ) : images && !images.length ? (
            <section className="form-no-response-container">
              No Images Found
            </section>
          ) : (
            <section className="form-no-response-container">
              {error ? error : <Loader />}
            </section>
          )}
        </div>
        {images && images.length ? (
          <div className="cloudinary-image-pagination">
            <button onClick={() => handleCursor("prev")}>
              <ArrowBackIosOutlinedIcon id="cloudinary-paginate-icon" />
            </button>
            <button onClick={() => handleCursor("next")}>
              <ArrowForwardIosOutlinedIcon id="cloudinary-paginate-icon" />
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </main>
  );
}
