import axios from "axios";
import { getItem } from "../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const getAllForms = async ({ pageId, websiteId, type, formId }) => {
  try {
    const response = await axios({
      url: `${base_url}/page/${pageId}/blocks?websiteId=${websiteId}&type=${type}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getFormResponse = async ({ formId, query }) => {
  const params = query ? `${formId}${query}` : formId;

  try {
    const response = await axios({
      url: `${base_url}/form/${params}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });

    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deleteFormResponse = async ({ formId, responseId }) => {
  try {
    const response = await axios({
      url:
        formId && responseId
          ? `${base_url}/form/${formId}/${responseId}`
          : `${base_url}/form/${formId}`,
      method: "DELETE",
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getFormCode = async ({ formId }) => {
  try {
    const response = await axios({
      url: `${base_url}/form/code/${formId}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });

    return response?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const generateCSV = async (id) => {
  try {
    const response = await axios({
      url: `${base_url}/form/csv/${id}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
      responseType: "blob",
    });

    return response;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getFormTypes = async () => {
  try {
    const response = await axios({
      url: `${base_url}/form/types`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getFormTags = async (formType) => {
  try {
    const response = await axios({
      url: `${base_url}/form/fields?formType=${formType}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });

    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

export {
  getAllForms,
  deleteFormResponse,
  getFormResponse,
  getFormCode,
  generateCSV,
  getFormTypes,
  getFormTags,
};
