import { NotifyUser } from "../../../../Components/Notification/index.js";
import { useState } from "react";
import { CustomDropdown } from "../../../../Components/Dropdown/customDropdown";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { IDTooltip } from "../../../../Components/Tooltip/Idtooltip.js";
import { deleteAuthIntegration } from "../../../../Api/Integration/auth.js";
import { Popup } from "../../../../Components/Popup/index.js";
import { UserList } from "./UserList.js";

export function Site({ obj, fetchSiteData, handleEdit }) {
  const { name, id } = obj;

  const [showOption, setShowOption] = useState(false);
  const [showUserPopup, setShowUserPopup] = useState(false);

  const handleMoreInfo = (e) => {
    // e.stopPropagation();
    setShowOption(true);
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation();
    const response = await deleteAuthIntegration(id);
    if (!response?.error) {
      fetchSiteData();
      NotifyUser({
        type: "success",
        message: `Website Deleted`,
      });
    }
  };

  const menu = [
    {
      title: "Edit",
      callBack: (e, id) => handleEdit(e, id),
    },
    {
      title: "Delete",
      callBack: (e, id) => handleDelete(e, id),
    },
    {
      title: "View Users",
      callBack: () => setShowUserPopup(true),
    },
  ];
  return (
    <div key={id} className='ind-integration'>
      <section className='recaptcha-site-details'>
        <section>
          <p>{name}</p>
          <IDTooltip value={id} title='Credential ID' />
        </section>

        <button onClick={handleMoreInfo}>
          <MoreVertOutlinedIcon />
        </button>
        {showOption ? (
          <CustomDropdown
            className='custom-dropdown-container-integration'
            showOption={showOption}
            setShowOption={setShowOption}
            menu={menu}
            id={id}
          />
        ) : (
          ""
        )}

        {showUserPopup ? (
          <Popup
            children={<UserList id={id} />}
            popup={showUserPopup}
            setPopup={setShowUserPopup}
            className='auth-user-response-popup'
            title={name}
          />
        ) : (
          ""
        )}
      </section>
    </div>
  );
}
