import { Button } from "../../../../Components/Button";
import { TextField } from "../../../../Components/TextField";
import { NotifyUser } from "../../../../Components/Notification/index.js";
import { useState, useEffect } from "react";
import {
  createEmailVerify,
  updateEmailVerify,
} from "../../../../Api/Integration/emailVerification";
import { urlValidation } from "../../../../utils/helperFn";

export function SiteCreate({
  setShowPopup,
  fetchSiteData,
  selectedSite,
  popupType,
}) {
  const [input, setInput] = useState({ name: "", url: "", brand: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const form = [
    {
      label: "Website Name",
      type: "text",
      variableName: "name",
    },
    {
      label: "Website Url",
      type: "text",
      variableName: "url",
    },
    {
      label: "Brand",
      type: "text",
      variableName: "brand",
    },
  ];

  useEffect(() => {
    if (
      selectedSite &&
      Object.keys(selectedSite).length &&
      popupType === "update"
    ) {
      setInput(selectedSite);
    }
  }, [selectedSite, popupType]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let temp = { ...input };
    temp[name] = value;
    setInput({ ...temp });
    setError("");
  };

  const handleClick = async () => {
    if (!Object.keys(input).length) {
      setError("All Fields Required");

      return;
    }

    for (let i in input) {
      if (input[i] === "") {
        setError("All Fields Required");
        return;
      }
    }

    const checkUrl = urlValidation(input?.url);
    if (!checkUrl) {
      setError("Invalid Url");

      return;
    }

    // return;
    setError("");

    setLoading(true);

    let response;

    if (popupType === "create") {
      response = await createEmailVerify(input);
    } else if (popupType === "update") {
      response = await updateEmailVerify(input);
    }

    setLoading(false);
    if (!response.error) {
      NotifyUser({
        type: "success",
        message: `Website ${popupType}d successfully`,
      });
      setShowPopup(false);
    } else {
      setError(response.message);
    }
    fetchSiteData();
  };

  return (
    <main>
      <form className='page-create-form'>
        {form.map(({ label, type, variableName }) => {
          if (type === "text") {
            return (
              <div className='ind-page-create-field' key={variableName}>
                <label className='formLabel'>{label}</label>
                <TextField
                  type={type}
                  onChange={handleChange}
                  name={variableName}
                  value={input[variableName]}
                />
              </div>
            );
          }

          return null;
        })}

        <p className='auth-error-message'>{error ? error : ""}</p>

        <Button
          title={popupType === "create" ? "Create" : "Update"}
          type='button'
          className='site-create-btn'
          onClick={handleClick}
          loading={loading}
        />
      </form>
    </main>
  );
}
