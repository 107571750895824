import { useParams } from "react-router-dom";
import { Recaptcha } from "./Reacaptcha";
import "./integrationPage.css";
import { Cloudinary } from "./Cloudinary";
import { Analytics } from "./GoogleAnalytics";
import { GoogleSheets } from "./GoogleSheets";
import { Hubspot } from "./Hubspot";
import { Cors } from "./Cors";
import { Mixpanel } from "./Mixpanel";
import { Clarity } from "./Clarity";
import { Waitlist } from "./Waitlist";
import { EmailVerification } from "./EmailVerification";
import { Authentication } from "./Auth";

export default function IntegrationPage() {
  const { type } = useParams();

  switch (type) {
    case "google-recaptcha":
      return <Recaptcha />;
    case "cloudinary":
      return <Cloudinary />;
    case "analytics":
      return <Analytics />;
    case "google-sheets":
      return <GoogleSheets />;
    case "hubspot":
      return <Hubspot />;
    case "cors":
      return <Cors />;
    case "mixpanel":
      return <Mixpanel />;
    case "clarity":
      return <Clarity />;
    case "waitlist":
      return <Waitlist />;
    case "email-verification":
      return <EmailVerification />;
    case "authentication":
      return <Authentication />;

    default:
      return null;
  }
}
