import { useEffect, useState } from "react";
import { TextField } from "../../../Components/TextField/index.js";
import { Loader } from "../../../Components/Loader";
import { dateFormatter } from "../../../utils/helperFn";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { getCloudinaryImages } from "../../../Api/Integration/cloudinary";
import { Dropdown } from "../../../Components/Dropdown";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

export function ImageLibrary({ saveLink, setShowLibrary }) {
  const [images, setImages] = useState("");
  const [filter, setFilter] = useState({ name: "", sort: "desc" });
  const [cursor, setCursor] = useState([""]);
  const [nextCursor, setNextCursor] = useState("");
  const [error, setError] = useState("");

  const fetchImages = async (obj) => {
    setError("");
    const response = await getCloudinaryImages(obj);

    if (response.error) {
      setError(response.message);
      return;
    }

    setImages(response.resources);

    if (!cursor.includes(response.next_cursor)) {
      setCursor([...cursor, response.next_cursor]);
    }
    setNextCursor(response.next_cursor);
  };

  useEffect(() => {
    fetchImages({});

    // eslint-disable-next-line
  }, []);

  const handleLink = (e, link) => {
    e.stopPropagation();
    window.open(link, "_blank");
  };

  const handleClick = (link) => {
    saveLink && saveLink(link);
    setShowLibrary && setShowLibrary(false);
  };

  const sortOptions = [
    { label: "Ascending", value: "asc" },
    { label: "Descending", value: "desc" },
  ];

  const handleChange = (e) => {
    let { value, name } = e.target;
    let temp = { ...filter };
    temp[name] = value;
    setFilter({ ...temp });
    fetchImages({ query: `&name=${temp.name}&sort=${temp.sort}` });
  };

  const handleCursor = (type) => {
    const getPrev = (nextCursor) => {
      let index = cursor.findIndex((item) => item === nextCursor);
      // console.log(cursor, index);
      return cursor[index - 2];
    };

    let pageCursor = type === "prev" ? getPrev(nextCursor) : nextCursor;

    fetchImages({
      query: `&name=${filter.name}&sort=${filter.sort}`,
      cursor: pageCursor ? pageCursor : "",
    });
  };

  return (
    <div className="image-library">
      <div className="image-library-filter-container">
        <TextField
          type="text"
          placeholder="Search"
          name="name"
          onChange={handleChange}
        />
        <Dropdown
          options={sortOptions}
          name="sort"
          value={filter.sort}
          className="image-library-filter-dropdown"
          onChange={handleChange}
        />
      </div>

      <div className="cloudinary-image-content">
        {images && images.length ? (
          images.map(({ filename, secure_url, uploaded_at }, i) => {
            return (
              <div
                key={i}
                className="cloudinary-ind-image-content"
                onClick={() => handleClick(secure_url)}
              >
                <section>
                  <img
                    src={secure_url}
                    loading="lazy"
                    alt={filename}
                    title={filename}
                  />
                  <p className="cloudinary-ind-image-name">{filename}</p>
                  <p className="cloudinary-ind-image-date">
                    {dateFormatter({ format: "DD/MM/YYYY", date: uploaded_at })}
                  </p>
                </section>
                <button onClick={(e) => handleLink(e, secure_url)}>
                  <OpenInNewOutlinedIcon />
                </button>
              </div>
            );
          })
        ) : images && !images.length ? (
          <section className="form-no-response-container">
            No Images Found
          </section>
        ) : (
          <section className="form-no-response-container">
            {error ? error : <Loader />}
          </section>
        )}
      </div>
      {images && images.length ? (
        <div className="cloudinary-image-pagination">
          <button onClick={() => handleCursor("prev")}>
            <ArrowBackIosOutlinedIcon id="cloudinary-paginate-icon" />
          </button>
          <button onClick={() => handleCursor("next")}>
            <ArrowForwardIosOutlinedIcon id="cloudinary-paginate-icon" />
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
