import { createSlice } from "@reduxjs/toolkit";

const websiteSlice = createSlice({
  name: "website",
  initialState: {
    siteName: "",
    siteId: "",
    pageName: "",
    pageId: "",
  },
  reducers: {
    websiteFn: (state, action) => {
      state.siteName = action.payload.siteName;
      state.siteId = action.payload.siteId;
      state.pageName = "";
      state.pageId = "";
    },
    pageFn: (state, action) => {
      state.pageName = action.payload.pageName;
      state.pageId = action.payload.pageId;
    },
  },
});

const websiteReducer = websiteSlice.reducer;
const websiteState = (state) => state.website;
const { websiteFn, pageFn } = websiteSlice.actions;

export { websiteReducer, websiteState, websiteFn, pageFn };
