import { useDispatch, useSelector } from "react-redux";
import "./account.css";
import { profileState } from "../../Redux/profileSlice";
import { HiddenField, TextField } from "../../Components/TextField";
import { useState } from "react";
import { Button } from "../../Components/Button";
import { updateProfile } from "../../Api/auth";
import { NotifyUser } from "../../Components/Notification";
import { loginFn } from "../../Redux/profileSlice";

export default function Account() {
  const profile = useSelector(profileState);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const form = [
    {
      title: "Name",
      variableName: "name",
      type: "text",
    },
    {
      title: "Email",
      variableName: "email",
      type: "text",
      validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message: "Invalid Email",
    },
    {
      title: "Update Password",
      variableName: "password",
      type: "password",
      validation: (value) =>
        !value
          ? true
          : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
              value
            ),
      message:
        "Password should have at least 8 characters, a letter, a number, and a special character",
    },
  ];

  const handleBlur = (obj) => {
    const { value, variableName, message, title, validation } = obj;
    if (!value && variableName !== "password") {
      setError({ ...error, [variableName]: `${title} is required` });
      return;
    }

    if (validation && !validation(value)) {
      setError({ ...error, [variableName]: message });

      return;
    }
  };

  const [input, setInput] = useState({
    name: profile.name,
    email: profile.email,
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    let temp = { ...input };
    temp[name] = value;
    setInput(temp);
    setError({ ...error, [name]: "" });
  };

  const handleClick = async () => {
    for (let i in input) {
      if (!input[i] && i !== "password") {
        return;
      }
    }

    for (let i in error) {
      if (error[i]) {
        return;
      }
    }

    setLoading(true);
    const response = await updateProfile(input);

    if (response.error) {
      NotifyUser({
        type: "error",
        message: response.message,
      });
    } else {
      dispatch(loginFn({ ...response, isLoggedIn: true }));
      NotifyUser({
        type: "success",
        message: "Profile Updated",
      });
    }

    setLoading(false);
  };

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <main className="account-page">
      <section className="site-heading-container">
        <h1>My Account</h1>
        <Button title="Log Out" onClick={logOut} />
      </section>

      <form className="account-field-container">
        {form.map(({ title, variableName, type, validation, message }, i) => {
          if (type === "text") {
            return (
              <div key={i} className="ind-account-field-container">
                <label className="formLabel">{title}</label>
                <TextField
                  // className="account-field"
                  type={type}
                  name={variableName}
                  value={input[variableName]}
                  onChange={handleChange}
                  onBlur={() =>
                    handleBlur({
                      title,
                      variableName,
                      value: input[variableName],
                      validation,
                      message,
                    })
                  }
                />
                <span className="profile-update-err-msg">
                  {error?.[variableName]}
                </span>
              </div>
            );
          }
          if (type === "password") {
            return (
              <div key={i} className="ind-account-field-container">
                <label className="formLabel">{title}</label>
                <HiddenField
                  // className="account-field"
                  type={type}
                  name={variableName}
                  value={input[variableName]}
                  onChange={handleChange}
                  onBlur={() =>
                    handleBlur({
                      title,
                      variableName,
                      value: input[variableName],
                      validation,
                      message,
                    })
                  }
                />
                <span className="profile-update-err-msg">
                  {error?.[variableName]}
                </span>
              </div>
            );
          }
          return null;
        })}

        <Button
          type="button"
          loading={loading}
          onClick={handleClick}
          title="Update"
          className="account-update-btn"
        />
      </form>
    </main>
  );
}
