import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Button } from "../../../../Components/Button";
import { Popup } from "../../../../Components/Popup";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAllAnalytics } from "../../../../Api/Integration/analytics";
import { Loader } from "../../../../Components/Loader";
import { Site } from "./Site";
import { SiteCreate } from "./SiteCreate";
import { CodeExample } from "./CodeExample";

export function Analytics() {
  const navigate = useNavigate();
  const [popupType, setPopupType] = useState("create");
  const [showPopup, setShowPopup] = useState(false);
  const [sites, setSites] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedSite, setSelectedSite] = useState("");
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [codeData, setCodeData] = useState("");

  const fetchSiteData = async () => {
    setLoading(true);
    const response = await getAllAnalytics();
    setSites(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSiteData();
  }, []);

  const handleEdit = (e, id) => {
    e.stopPropagation();
    const filterSite = sites.filter((obj) => obj.id === id);
    if (!filterSite[0]) {
      return;
    }
    setPopupType("update");
    setSelectedSite(filterSite[0]);
    setShowPopup(true);
  };

  const handleClick = (obj) => {
    setShowCodePopup(true);
    setCodeData(obj);
  };

  return (
    <main className="ind-integration-page">
      {showPopup ? (
        <Popup
          children={
            <SiteCreate
              setShowPopup={setShowPopup}
              fetchSiteData={fetchSiteData}
              selectedSite={selectedSite}
              popupType={popupType}
            />
          }
          popup={showPopup}
          setPopup={setShowPopup}
          title="Website"
          className="ga-integration-form-popup"
        />
      ) : (
        ""
      )}

      {showCodePopup ? (
        <Popup
          children={
            <div className="code-display-container">
              <CodeExample obj={{ ...codeData }} />
            </div>
          }
          popup={showCodePopup}
          setPopup={setShowCodePopup}
          title="Sample Code"
          className="analytics-code-form-popup"
        />
      ) : (
        ""
      )}

      <section className="site-heading-container">
        <h1>
          {" "}
          <Button
            title={<KeyboardBackspaceOutlinedIcon />}
            className="navigate-btn"
            onClick={() => navigate(-1)}
          />
          Google Analytics
        </h1>

        <Button
          title="+ Add Credential"
          onClick={() => {
            setPopupType("create");
            setShowPopup(true);
          }}
        />
      </section>
      <section className="site-list-container">
        {sites && sites.length ? (
          sites.map((obj, i) => {
            return (
              <Site
                obj={obj}
                key={i}
                handleEdit={handleEdit}
                fetchSiteData={fetchSiteData}
                handleClick={handleClick}
              />
            );
          })
        ) : sites && !loading ? (
          <section className="message-container">
            <p>No Recent Websites</p>
          </section>
        ) : (
          <section className="message-container">
            <Loader />
          </section>
        )}
      </section>
    </main>
  );
}
