import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { profileReducer } from "./profileSlice";
import { blockReducer } from "./blockSlice";
import { listReducer } from "./listSlice";
import { websiteReducer } from "./websiteSlice";
import { formReducer } from "./formSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["profile", "website"],
};

const rootReducer = combineReducers({
  profile: profileReducer,
  blocks: blockReducer,
  lists: listReducer,
  website: websiteReducer,
  forms: formReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});
